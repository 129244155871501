import { useMutation } from '@apollo/client';
import { Box, styled, useTheme } from '@mui/material';
import * as React from 'react';
import { FILE_FRAGMENT } from '../../../../fragements/file';

import { graphql, useFragment } from '../../../../gql';
import useConfirmDialog from '../../../../Hooks/useConfirmDialog';
import BotIndicator from '../../../common/BotIndicator/BotIndicator';
import CustomBox from '../../../common/CustomBox/CustomBox';
import useDisplayMessage from '../../../common/GlobalMessage/useDisplayMessage';
import Loading from '../../../common/Loading/Loading';
import UserAvatar from '../../../common/UserAvatar/UserAvatar';
import ActionMessage from './ActionMessage/ActionMessage';
import ContentMessage from './ContentMessage/ContentMessage';
import ContextMenu from './ContextMenu/ContextMenu';
import MessageBotPrompts from './MessageBotPrompts/MessageBotPrompts';
import MessageFiles from './MessageFiles/MessageFiles';
import MessageReplyFrom from './MessageReplyFrom/MessageReplyFrom';
import MessageTime from './MessageTime/MessageTime';
import { onAfterRemoveMessage } from '../../utils/onAfterRemoveMessage';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import BotLimitReachedCTA from './BotLimitReachedCTA/BotLimitReachedCTA';
import { ChatMessageFragmentFragment } from '../../../../gql/graphql';
import useRegenerateBotResponse from '../../../../Hooks/useRegenerateBotResponse';
import { isEmptyString } from '../../../../utils/stringUtils';
import BotSuggestions from './BotSuggestions/BotSuggestions';
import HTMLPreview from './HTMLPreview/HTMLPreview';

const REMOVE_CHAT_MESSAGE = graphql(`
  mutation RemoveChatMessage($messageId: Float!) {
    removeChatMessage(messageId: $messageId) {
      id
      createdAt
      content
      sentByUserId
      todoChatId
      updatedAt

      files {
        id
        name
        url
        type
        size
      }
      sentBy {
        id
        firstName
        lastName
        profilePicture
        isBot
      }
      __typename
    }
  }
`);

const ReplyFromContainer = styled(Box)(({ theme }) => ({
  marginLeft: '5px',
}));

// Styled DownloadingProgressBox
const DownloadingProgressBox = styled(Box)({
  width: '100%',
});

interface IProps {
  message: ChatMessageFragmentFragment;
  todoId?: number;
  isPlaying: boolean;
  isSpeechAudioLoading: boolean;
  handleAudioToggle(): void;
  onReply: (messageId: number) => void;
  sessionId: number;
  isLastMessage?: boolean;
}

// TODO: Simplifier ce fonction

const Message = ({
  message,
  todoId,
  onReply,
  isSpeechAudioLoading,
  sessionId,
  isPlaying,
  handleAudioToggle,
  isLastMessage,
}: IProps) => {
  const sentBy = message?.sentBy;
  const { regenerateBotResponse, loading: loadingRegeneration } =
    useRegenerateBotResponse();
  const files = useFragment(FILE_FRAGMENT, message?.files);
  let userProfil = sentBy?.isBot
    ? sentBy?.profilePicture || '/default-bot.png'
    : sentBy?.profilePicture;

  // Downloading files state
  const { displayConfirmDialog } = useConfirmDialog();
  const [downloadingFiles, setDownloadingFiles] = React.useState(false);
  const { displayMessage } = useDisplayMessage();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isShow, setIsShow] = React.useState(false);
  const theme = useTheme();
  const [removeChatMessage] = useMutation(REMOVE_CHAT_MESSAGE);

  const handleDeleteMessage = () => {
    displayConfirmDialog({
      message: 'chat.deletMessage.title.dialog',
      onConfirm: () => {
        removeChatMessage({
          variables: {
            messageId: message.id,
          },
          update: (cache, { data }) => {
            setAnchorEl(null);
            // Modify the cache by removing the deleted message
            onAfterRemoveMessage({
              cache,
              currentMessage: data?.removeChatMessage,
              sessionId: sessionId,

              todoId,
            });
          },
        });
      },
    });
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsShow(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsShow(false);
  };

  // Handle reply menu click
  const handleReply = () => {
    onReply(message.id);
  };

  const handleDownloadFiles = async () => {
    if (!files) return;
    const fileWithUrl = files
      .filter((file) => file.url)
      .map((file) => file.url as string);
    setDownloadingFiles(true);
    fileWithUrl.forEach(async (url) => {
      // Create a new tab with the file url

      window.open(url, '_blank');
    });
    setDownloadingFiles(false);
  };

  // Handle copy message
  const handleCopyClick = () => {
    navigator.clipboard.writeText(message.content as string).then(() => {
      displayMessage({
        type: 'success',
        message: 'message.copy.success',
      });
    });
  };

  const isMessageFile = message.files && message.files.length > 0;
  const isHaveBotPrompts = React.useMemo(
    () => message?.botPrompts && message?.botPrompts.length > 0,
    [message?.botPrompts]
  );

  const handleRegenerateBotResponse = React.useCallback(() => {
    regenerateBotResponse({
      variables: {
        messageId: message.id,
      },
    });
  }, [message.id, regenerateBotResponse]);
  const shouldShowRefreshButton = React.useMemo<boolean>(
    () =>
      Boolean(
        message?.sentBy?.bot?.id &&
          isLastMessage &&
          !loadingRegeneration &&
          !message.isBotError &&
          message?.sentBy?.bot?.supportRegeneration
      ),
    [message.isBotError, loadingRegeneration, message?.sentBy, isLastMessage]
  );
  const isDisplayText2SpeechButton = React.useMemo<boolean>(
    () =>
      Boolean(
        message?.sentBy?.bot?.id &&
          !loadingRegeneration &&
          !message.isBotError &&
          !isEmptyString(message?.content)
      ),
    [message.isBotError, loadingRegeneration, message?.sentBy]
  );
  const isDisplayLimitReachedCTA = React.useMemo<boolean>(
    () =>
      Boolean(
        message.isSubscriptionUsageLimitReachedMessage &&
          sentBy?.bot?.id &&
          isLastMessage
      ),
    [
      message.isSubscriptionUsageLimitReachedMessage,
      sentBy?.bot?.id,
      isLastMessage,
    ]
  );
  const isDisplayHtmlPreview = React.useMemo<boolean>(
    () => Boolean(message?.htmlContent),
    [message?.htmlContent]
  );
  return (
    <CustomBox
      width={'100%'}
      id={`message-${message.id}`}
      mb={theme.spacing(1)}
    >
      <CustomBox
        onMouseEnter={() => setIsShow(true)}
        onMouseLeave={() => setIsShow(false)}
        sx={{
          transition: 'all 0.1s',
          width: '100%',
          background: theme.palette.customColor.messageBackground,
          borderRadius: theme.spacing(1),
          marginBottom: theme.spacing(1),
        }}
      >
        <ReplyFromContainer>
          <MessageReplyFrom messageId={message.id} />
        </ReplyFromContainer>
        <ContextMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          isMessageFile={isMessageFile}
          handleDownloadFiles={handleDownloadFiles}
          handleDelete={() => handleDeleteMessage()}
        />
        <CustomBox
          display="flex"
          p={theme.spacing(1)}
          gap={theme.spacing(1)}
          width={'100%'}
          alignItems="normal"
        >
          <UserAvatar userProfil={userProfil} />
          <CustomBox width={'100%'}>
            <CustomBox width={'100%'}>
              <CustomBox
                sx={{ width: '100%' }}
                display="flex"
                gap={theme.spacing(1)}
                alignItems={'start'}
                height={theme.spacing(3.5)}
              >
                <CustomTypography variant="h5" fontWeight="bold">
                  {sentBy?.firstName}{' '}
                  <MessageTime createdAt={message.createdAt} />
                </CustomTypography>

                {(isPlaying || isShow) && (
                  <CustomBox className="action-body" ml={'auto'}>
                    <CustomBox
                      className="action-message"
                      sx={{
                        // position: 'absolute',
                        right: 0,
                        top: 10,
                        zIndex: 1,
                      }}
                    >
                      <ActionMessage
                        onReply={handleReply}
                        isDisplayText2SpeechButton={isDisplayText2SpeechButton}
                        handleCopyClick={handleCopyClick}
                        handleContextMenu={handleClick}
                        shouldShowRefreshButton={shouldShowRefreshButton}
                        handleRegenerateBotResponse={
                          handleRegenerateBotResponse
                        }
                        isPlaying={isPlaying}
                        isSpeechAudioLoading={isSpeechAudioLoading}
                        handleAudioToggle={handleAudioToggle}
                      />
                    </CustomBox>
                  </CustomBox>
                )}
              </CustomBox>
              <CustomBox
                sx={{
                  width: '100%',
                  pr: theme.spacing(3),
                }}
              >
                <ContentMessage
                  message={message.content}
                  metaSources={message.messageMetaSources}
                />
              </CustomBox>
              {/* // Display the html preview in iframe  */}
              {isDisplayHtmlPreview && message?.htmlContent && (
                <HTMLPreview
                  htmlContent={message?.htmlContent}
                  messageId={message.id}
                />
              )}

              {downloadingFiles && (
                <DownloadingProgressBox>
                  <Loading />
                </DownloadingProgressBox>
              )}
            </CustomBox>
            <CustomBox sx={{ width: '100%' }}>
              {message.files ? <MessageFiles files={message?.files} /> : null}
            </CustomBox>
          </CustomBox>
        </CustomBox>
      </CustomBox>
      {isDisplayLimitReachedCTA ? (
        <CustomBox
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
          pl={5}
          mb={1}
        >
          <BotLimitReachedCTA botId={sentBy?.bot?.id || 0} />
        </CustomBox>
      ) : (
        <CustomBox>
          {isHaveBotPrompts && (
            <CustomBox
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                //marginLeft: (theme) => theme.spacing(5),
              }}
              mr={1}
              width={'100%'}
            >
              <MessageBotPrompts
                messageId={message.id}
                // @ts-ignore
                botPrompts={message?.botPrompts}
                todoId={todoId}
              />
            </CustomBox>
          )}
        </CustomBox>
      )}
    </CustomBox>
  );
};

export default React.memo(Message);
