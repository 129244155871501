import { FC, useRef, useState } from 'react';
import CustomBox from '../CustomBox/CustomBox';
import useCreateFile, { FileType } from '../../../Hooks/useCreateFile';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  useTheme,
} from '@mui/material';
import WebcamCapture from './WebCam';
import { StyledImage } from './STImageForm.style';
import CustomIconButton from '../CustomIconButton/CustomIconButton';
import CustomTypography from '../CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import { usePaste } from '../../../Hooks/usePaste';
interface IProps {
  onAddFiles(files: FileType[]): void;
  supportedFiletypes?: string;
  loading?: boolean;
  placeholder?: string | null;
  size?: 'large' | 'small';
  isDisplayLoader?: boolean;
  isDirectlyToUpload?: boolean;
  maxFileSize?: number;
  value: any;
}

const STImageForm: FC<IProps> = ({
  onAddFiles,
  supportedFiletypes,
  value,
  maxFileSize,
  placeholder,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isWebCam, setIsWebCam] = useState(false);

  const toogleChooseWebCam = () => {
    setIsWebCam((lastedState) => !lastedState);
    onAddFiles([]);
  };

  const { uploadProgress, isInsupported, loading, onUploadFile } =
    useCreateFile({
      onComplete: (files: FileType[]) => {
        onAddFiles(files);
      },
      supportedFiletypes,
    });

  const { onPasteFile } = usePaste({ callBack: onUploadFile });
  const theme = useTheme();

  const handleUploadImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input's click event
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      onUploadFile(event.target.files);
    }
  };

  const handleCaptureWebCam = (image: FileList) => {
    onUploadFile(image);
  };

  const handleResetCaptureWebCam = () => {
    onAddFiles([]);
  };

  const onChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    onAddFiles([
      {
        url: event.target.value,
        id: 0,
        updatedAt: '',
        type: '',
        size: 0,
        removed: false,
        name: '',
        createdAt: '',
      },
    ]);
  };

  const resetImageUpload = () => {
    onAddFiles([]);
  };

  return (
    <CustomBox>
      {isWebCam ? (
        <WebcamCapture
          onExitWebCam={toogleChooseWebCam}
          onUploadImage={handleCaptureWebCam}
          onResetImage={handleResetCaptureWebCam}
          loadingUpload={loading}
          uploadProgress={uploadProgress}
          value={value || ''}
        />
      ) : (
        <>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }} // Hide the input
            value=""
          />

          <FormControl
            sx={{
              width: '100%',
            }}
            variant="outlined"
          >
            <OutlinedInput
              type="text"
              sx={{
                '&.MuiOutlinedInput-root': {
                  border: isInsupported
                    ? `2px solid ${theme.palette.error.main}`
                    : 0,
                  borderRadius: '4px',
                },
              }}
              disabled={loading}
              value={value || ''}
              onChange={onChangeUrl}
              onPaste={onPasteFile}
              placeholder={placeholder || ''}
              endAdornment={
                loading ? (
                  <CustomBox
                    sx={{
                      display: 'flex',
                      width: '75px',
                      alignItems: 'center',
                      gap: '5px',
                      color: (theme) => theme.palette.grey[200],
                    }}
                  >
                    <b>{uploadProgress} %</b>
                    <CircularProgress size={15} />
                  </CustomBox>
                ) : (
                  <>
                    <InputAdornment position="end">
                      <IconButton onClick={handleUploadImage} edge="end">
                        <CloudUploadIcon />
                      </IconButton>
                    </InputAdornment>
                    <InputAdornment position="end">
                      <IconButton onClick={toogleChooseWebCam} edge="end">
                        <PhotoCameraIcon />
                      </IconButton>
                    </InputAdornment>
                  </>
                )
              }
            />
          </FormControl>
          {isInsupported && (
            <CustomTypography
              sx={{
                color: theme.palette.error.main,
                fontSize: '14px',
              }}
            >
              <FormattedMessage id={'unsupported.file.label'} />
            </CustomTypography>
          )}
          {value && !loading && !isInsupported ? (
            <CustomBox
              sx={{
                borderColor: (theme) => theme.palette.grey[200],
                padding: (theme) => theme.spacing(2),
                marginTop: (theme) => theme.spacing(1),
                borderRadius: (theme) => theme.spacing(1),
                position: 'relative',
                border: '1px solid',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 'auto',
              }}
            >
              <CustomIconButton
                onClick={resetImageUpload}
                edge="end"
                sx={{
                  position: 'absolute',
                  right: (theme) => theme.spacing(1),
                  top: 0,
                }}
              >
                <CloseIcon />
              </CustomIconButton>
              <StyledImage src={value} alt="preview image uplaoded" />
            </CustomBox>
          ) : null}
        </>
      )}
    </CustomBox>
  );
};

export default STImageForm;
