import { useTheme } from '@mui/material';
import CustomButton from '../CustomButton/CustomButton';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';

const STStartChatBotButton = ({ botId }: { botId: number }) => {
  const intl = useIntl();
  const theme = useTheme();
  const { workspaceId, sessionId } = useParams();
  if (sessionId) return null;
  return (
    <CustomButton
      variant="text"
      href={`../../../${workspaceId}/talk/?bot=${botId}`}
      fullWidth
      label={intl.formatMessage({
        id: 'button.start.chat',
      })}
      size="small"
      sx={{
        height: theme.spacing(4),
        borderRadius: theme.spacing(0.7),
        borderColor: theme.palette.grey[200],
        width: (theme) => theme.spacing(18),

        '&:hover': {
          borderColor: theme.palette.grey[200],
        },
      }}
      endIcon={<EastIcon fontSize="small" />}
    />
  );
};
export default STStartChatBotButton;
