// Material ui custom menu component using material-ui Menu component
// The component takes an array of menu items and renders them as a menu

import { FC, ReactNode, useState } from 'react';

import {
  ClickAwayListener,
  Menu,
  MenuItem,
  MenuList,
  MenuProps,
} from '@mui/material';

import { useIntl } from 'react-intl';
import React from 'react';

interface IProps extends MenuProps {
  menuItems: {
    component?: ReactNode;
    onClick?: (e?: any) => void;
    intlId?: string;
  }[];
  anchorEl: any;
  handleClose?(): void;
}

const STMenu: FC<IProps> = ({
  menuItems,
  anchorEl,
  handleClose: handleCloseMenu,
  ...rest
}) => {
  const intl = useIntl();

  const [anchorElState, setAnchorElState] = useState<HTMLElement | null>(
    anchorEl
  );

  const handleClose = () => {
    setAnchorElState(null);
    if (handleCloseMenu) handleCloseMenu();
  };

  React.useEffect(() => {
    setAnchorElState(anchorEl);
  }, [anchorEl]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        {...rest}
        open={Boolean(anchorElState)}
        sx={{
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        <MenuList>
          {menuItems.map((item) => (
            <MenuItem
              key={item.intlId}
              onClick={(e: any) => {
                if (item.onClick) {
                  item.onClick(e);
                  handleClose();
                }
              }}
            >
              {item.component
                ? item.component
                : intl.formatMessage({ id: item.intlId })}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </ClickAwayListener>
  );
};

export default React.memo(STMenu);
