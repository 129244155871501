//@ts-nocheck
import { useTheme, styled, IconButton } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import remarkMath from 'remark-math';
import Box from '@mui/material/Box';
import remarkGfm from 'remark-gfm';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import { CopyAllOutlined } from '@mui/icons-material';
import 'katex/dist/katex.min.css'; // `rehype-katex` does not import the CSS for you

import useDisplayMessage from '../../../../common/GlobalMessage/useDisplayMessage';
import CustomButton from '../../../../common/CustomButton/CustomButton';
import MessageMetaSources from './MessageMetaSources/MessageMetaSources';
import useChangePDFPage from '../../../hooks/useChangePDFPage';
import getMetaSourceFromString from './getMetaSourceFromString';
import useGetChatContext from '../../ChatBodyContainer/useGetChatContext';
import React from 'react';
import MetaSourcePageBadge from './MessageMetaSources/MetaSourcePageBadge';
import rehypeKatex from 'rehype-katex';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import language from 'react-syntax-highlighter/dist/esm/languages/hljs/1c';
import STMermaid from '../../../../common/STMermaid/STMermaid';
const CodeContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
}));
const BoxStyle = styled(Box)(({ theme }) => ({
  backgroundColor: '#343541',
  width: '100%',
  height: theme.spacing(4),
  display: 'flex',
  padding: theme.spacing(1),
}));
const BoxMessage = styled(Box)(({ theme }) => ({
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  '& .table-header:first-child': {
    borderTopLeftRadius: theme.spacing(1),
  },
  '& .table-header:last-child': {
    borderTopRightRadius: theme.spacing(1),
  },
}));

const InlineCode = styled('code')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#363546' : '#d9d9d9',
  borderRadius: '5px',
  padding: '0.2em 0.4em',
  fontWeight: 700,
  color: '#b24d4f',
  fontFamily: 'monospace',
}));
interface props {
  message: String | any; //| Array<Object>
  style?: any;
  metaSources?: any;
}

const ContentMessage = React.memo(({ message: text, metaSources }: props) => {
  const { displayMessage } = useDisplayMessage();
  const { setPdfViewerPage } = useChangePDFPage();
  const chatContext = useGetChatContext();

  const theme = useTheme();
  const color = theme.palette.text.primary;
  const defaultCommonStyle = {
    color: theme.palette.text.primary,
    fontWeight: '400',
    width: '98%',
    //fontFamily: 'Lato',
    lineHeight: '26px',
    marginTop: '0px',
    marginBottom: '0px',
  };

  const handleCopy = () => {
    displayMessage({ message: 'code.copied', type: 'success' });
  };

  const onPageSourceClick = ({ page, url }: any) => {
    const thePage = parseInt(page.trim());
    setPdfViewerPage({
      page: thePage,
      url,
    });
  };
  const onClickLinkSource = (sourceId?: number, page?: number) => {
    if (!sourceId || page === undefined) return;
    // Find source url from metaSources using sourceId
    const source = chatContext.dataSources.find(
      (source: any) => source.id === sourceId
    );
    const thePage = page === 0 ? page + 1 : page;
    if (source && source.url && page >= 0) {
      setPdfViewerPage({
        page: thePage,
        url: source.url,
      });
    }
  };
  const isMermaid = (language: string) => {
    return language === 'mermaid';
  };

  return (
    <BoxMessage
      sx={{
        width: '100%',
        position: 'relative',
        lineHeight: '36px',
        '& .katex': {
          margin: 0,
          padding: 0,
        },
        '& .katex-display': {
          margin: '0 auto',
        },
      }}
    >
      <ReactMarkdown
        children={text}
        remarkPlugins={[remarkGfm, remarkMath]}
        //@ts-ignore
        rehypePlugins={[rehypeKatex]}
        components={{
          p: ({ node, ...props }) => (
            <p
              style={{
                ...defaultCommonStyle,
                whiteSpace: 'pre-wrap',
              }}
              {...props}
            />
          ),
          a: ({ node, href, ...props }) => {
            const pattern = /#source-(\d+)-(\d+)/;
            if (href) {
              const match = href.match(pattern);
              if (match) {
                const metaSource = getMetaSourceFromString(href);
                if (!metaSource) return null;
                // If meta source page undefined, return  null
                if (metaSource?.page === undefined) return null;

                return (
                  <MetaSourcePageBadge
                    onClick={() =>
                      onClickLinkSource(
                        metaSource?.datasourceId,
                        metaSource?.page
                      )
                    }
                    page={metaSource?.page}
                  />
                );
              }
            }

            return (
              <a
                {...props}
                style={{ ...defaultCommonStyle }}
                target="_blank"
                rel="noopener noreferrer"
                href={href}
              />
            );
          },
          h1: ({ node, ...props }) => (
            <CustomTypography
              sx={{ color, fontSize: '15px' }}
              variant="h1"
              {...props}
            />
          ),
          h2: ({ node, ...props }) => (
            <CustomTypography
              sx={{ color, fontSize: '15px' }}
              variant="h2"
              {...props}
            />
          ),
          h3: ({ node, ...props }) => (
            <CustomTypography
              sx={{ color, fontSize: '15px' }}
              variant="h3"
              {...props}
            />
          ),
          h4: ({ node, ...props }) => (
            <CustomTypography
              sx={{ color, fontSize: '15px' }}
              variant="h4"
              {...props}
            />
          ),
          h5: ({ node, ...props }) => (
            <CustomTypography
              sx={{ color, fontSize: '15px' }}
              variant="h5"
              {...props}
            />
          ),
          h6: ({ node, ...props }) => (
            <CustomTypography
              sx={{ color, fontSize: '15px' }}
              variant="h6"
              {...props}
            />
          ),
          blockquote: ({ node, ...props }) => (
            <blockquote {...props} style={{ ...defaultCommonStyle }} />
          ),
          ul: ({ node, ...props }) => <ul {...props} />,
          li: ({ node, ...props }) => (
            <li {...props} style={{ ...defaultCommonStyle }} />
          ),

          table: ({ node, ...props }) => (
            <CustomBox sx={{ ...defaultCommonStyle, overflow: 'auto' }}>
              <table
                {...props}
                style={{
                  borderSpacing: 0,
                  width: '100%',
                }}
              />
            </CustomBox>
          ),
          th: ({ node, index, ...props }) => (
            <th
              {...props}
              className="table-header"
              style={{
                backgroundColor: theme.palette.customColor.scrollColor,
                border: `1px solid ${theme.palette.grey[200]}`,
                padding: theme.spacing(1),
                wordBreak: 'keep-all',
                textAlign: 'start',
                fontSize: '14px',
              }}
            />
          ),
          tr: ({ node, ...props }) => <tr {...props} />,
          td: ({ node, ...props }) => (
            <td
              {...props}
              style={{
                textAlign: 'start',
                border: `1px solid ${theme.palette.grey[200]}`,
                padding: theme.spacing(1),
                wordBreak: 'keep-all',
              }}
            />
          ),

          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            const codePosition = node.position?.start.line;
            const language = match ? match[1] : 'text';
            if (isMermaid(language) && !inline) {
              return (
                <CustomBox p={2}>
                  <STMermaid chart={String(children)} />
                </CustomBox>
              );
            }

            return !inline && match ? (
              <CodeContainer>
                <BoxStyle>
                  <CopyToClipboard text={String(children)} onCopy={handleCopy}>
                    <Box ml="auto" display={'flex'}>
                      <CustomButton
                        variant="outlined"
                        size="small"
                        startIcon={<CopyAllOutlined />}
                        intlId="message.copy.code"
                        sx={{
                          color: 'white',
                        }}
                      />
                    </Box>
                  </CopyToClipboard>
                </BoxStyle>
                <Box className={className} id={`code-${codePosition}`}>
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, '')}
                    style={dracula as any}
                    PreTag="div"
                    language={match ? match[1] : 'text'}
                    {...props}
                  />
                </Box>
              </CodeContainer>
            ) : (
              <InlineCode className={className} {...props}>
                {children}
              </InlineCode>
            );
          },
        }}
      />
      {Boolean(metaSources && metaSources.length) && (
        <MessageMetaSources
          metaSources={metaSources}
          onSourceClick={onPageSourceClick}
        />
      )}
    </BoxMessage>
  );
});

export default ContentMessage;
