// ChatLayout component
import { Box } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import CustomBox from '../common/CustomBox/CustomBox';
import LazyBoundary from '../LazyBoundary/LazyBoundary';
import { ChatContext } from './Chat';
import ChatSession from './ChatSession/ChatSession';
import useGetInfiniteTodo from './hooks/useGetInfiniteTodo';
import useExtraConfig from './hooks/useExtraConfig';

const ChatLayout = () => {
  // For my assistant
  const { infiniteTodoId } = useGetInfiniteTodo();
  const { extraConfig, onChangeExtraConfig } = useExtraConfig();
  const params = useParams();
  const todoIdParam = params?.taskID ? parseInt(params?.taskID) : 0;
  const todoId = todoIdParam || infiniteTodoId || 0;

  return (
    <ChatContext.Provider
      value={{
        isInifiniteChat: infiniteTodoId === todoId,
        extraConfig,
        onChangeExtraConfig,
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <CustomBox
          sx={{
            width: (theme) => theme.spacing(30),
            position: 'relative',
            paddingLeft: (theme) => theme.spacing(1),
            paddingRight: (theme) => theme.spacing(1),
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
            },

            backgroundColor: (theme) =>
              theme.palette.customColor.chatSessionBackground,
            height: 'calc(100vh - 50px)',
            pt: '8px',
          }}
        >
          <ChatSession todoId={todoId} onChatSessionChange={(t) => {}} />
        </CustomBox>

        <LazyBoundary isPrintError={true} size={30}>
          <Outlet />
        </LazyBoundary>
      </Box>
    </ChatContext.Provider>
  );
};

export default ChatLayout;
