import { FC, FormEvent, useContext, useEffect } from 'react';
import { ChatContext } from '../../../Chat';
import useSentNewMessage from '../../../hooks/useSendNewMessage';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomButton from '../../../../common/CustomButton/CustomButton';
import STDynamicForms from '../../../../common/STDynamicForm/STDynamicForm';
import { FormField } from '../../../../../gql/graphql';
import useDisplayMessage from '../../../../common/GlobalMessage/useDisplayMessage';

interface IFormFields {
  formFields: FormField[] | [];
  option?: {
    allowsendNewMessage?: boolean;
    todoId: number;
    sessionId: number;
    botId: number;
  };
}
const BotExtraConfig: FC<IFormFields> = ({ formFields, option }) => {
  const { extraConfig, onChangeExtraConfig } = useContext(ChatContext);
  const { displayMessage } = useDisplayMessage();

  const { addNewMessage, loadSendMessage } = useSentNewMessage({
    taskID: option?.todoId || 0,
  });

  const setDefaultValueData = () => {
    formFields.forEach((formField) => {
      // Reset only formName in formFields
      if (formField.defaultValue !== undefined) {
        onChangeExtraConfig(formField.formName, formField.defaultValue);
      }
    });
  };

  useEffect(() => {
    setDefaultValueData();
  }, [formFields.length]);

  const handleSendMessage = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    addNewMessage({
      variables: {
        newMessageData: {
          mentionedBotId: option?.botId,
          sessionId: +(option?.sessionId || 0),
          extraConfig,
        },
      },

      onError() {
        displayMessage({
          message: 'send.image.error',
          type: 'error',
        });
      },
    });
  };

  return (
    <form onSubmit={handleSendMessage}>
      <CustomBox
        pt={1}
        sx={{
          wordWrap: 'break-word',
          overflow: 'hidden',
          maxHeight: '84vh',
          overflowY: 'auto',
          paddingRight: (theme) => theme.spacing(1),
          boxSizing: 'border-box',
        }}
      >
        <STDynamicForms
          fields={formFields as any}
          currentValues={extraConfig}
          onChange={onChangeExtraConfig}
        />
      </CustomBox>
      <CustomBox
        m={1}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: (theme) => theme.spacing(1),
        }}
      >
        <CustomButton
          onClick={setDefaultValueData}
          intlId="drawer.button.sitting.extraConfig.reset"
          disabled={loadSendMessage}
          variant="outlined"
        />
        {option?.allowsendNewMessage ? (
          <CustomButton
            type="submit"
            variant="contained"
            intlId="chat.form.send.message"
            isLoading={loadSendMessage}
          />
        ) : null}
      </CustomBox>
    </form>
  );
};

export default BotExtraConfig;
