import { useParams } from 'react-router-dom';
import { graphql } from '../../../gql';
import { useMutation } from '@apollo/client';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import { GET_CHAT_SESSIONS } from './useGetAllChatSessions';
import useConfirmDialog from '../../../Hooks/useConfirmDialog';
import { useIntl } from 'react-intl';

const DELETE_CHAT_SESSION = graphql(`
  mutation DeleteChatSession($id: Float!) {
    deleteTodoChatSession(id: $id) {
      id
      title
    }
  }
`);
const useDeleteSession = ({ todoId }: { todoId: number }) => {
  const params = useParams();
  const intl = useIntl();
  const { displayConfirmDialog } = useConfirmDialog();
  const [deleteSession] = useMutation(DELETE_CHAT_SESSION, {
    variables: {
      id: params?.sessionId ? +params?.sessionId : 0,
    },
    update(cache, { data }) {
      if (!data) {
        return;
      }
      Mixpanel.track('Delete chat session', {});

      const existingChatSessions: any = cache.readQuery({
        query: GET_CHAT_SESSIONS,
        variables: {
          todoId,
        },
      });
      const newSession = existingChatSessions.getTodoChatSessions.filter(
        (session: any) => session.id != data?.deleteTodoChatSession.id
      );
      cache.writeQuery({
        query: GET_CHAT_SESSIONS,
        variables: {
          todoId,
        },
        data: {
          getTodoChatSessions: [...newSession],
        },
      });
    },
  });
  const handleDeleteSession = () => {
    displayConfirmDialog({
      message: intl.formatMessage({ id: 'deleteSession.confirm' }),
      onConfirm: () => {
        deleteSession();
      },
    });
  };
  return { handleDeleteSession, deleteSession };
};
export default useDeleteSession;
