import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import CustomBox from '../../common/CustomBox/CustomBox';

// Styled box for right container, scrollable and fixed height based on viewport
export const RightContainer = styled(CustomBox)(({ theme }) => ({
  height: 'calc(100vh - 50px)',
  overflowY: 'auto',
  width: '100%',
  overflowX: 'hidden',
  backgroundColor: theme.palette.customColor.chatDescriptionBackground,
}));

export const LeftContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(0),
  borderRight: `1px solid ${theme.palette.divider}`,
  // backgroundColor: theme.palette.customColor.chatBackground,
}));
