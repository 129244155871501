import Moment from 'react-moment';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import UserAvatar from '../../../../common/UserAvatar/UserAvatar';
interface UserProfilType {
  alt?: string;
  userName: string;
  userProfil: string;
  userEmail?: string;
  sx?: any;
}
const UserProfil = ({
  alt,
  userName,
  userProfil,
  userEmail,
  sx,
}: UserProfilType) => {
  return (
    <CustomBox display={'flex'} alignItems={'center'}>
      <CustomBox mr={1}>
        <UserAvatar
          sx={sx ?? { width: 21, height: 21 }}
          alt={alt}
          userProfil={userProfil}
        />
      </CustomBox>
      <CustomBox>
        <CustomTypography variant="h6">{userName}</CustomTypography>
        {userEmail && (
          <CustomTypography fontSize={'12px'} variant="body1">
            {userEmail}
          </CustomTypography>
        )}
      </CustomBox>
    </CustomBox>
  );
};
export default UserProfil;
