import { Box, CircularProgress, IconButton, useTheme } from '@mui/material';
import Prompts from '../../../../../Prompts/Prompts';
import CustomTooltip from '../../../../../common/CustomToolTip/CustomToolTip';
import { FormattedMessage } from 'react-intl';
import StopRecording from '../SvgIcon/StopRecording';
import PapperClipAttache from '../SvgIcon/PapperClipAttache';
import SendIcon from '../SvgIcon/SendIcon';
import ScreenCastIcon from '../SvgIcon/ScreenCastIcon';
import useGetChatSessionDataSource from '../../../../hooks/useGetChatSessionDataSource';
import { ChatContext } from '../../../../Chat';
import React from 'react';
import SpeechToText from '../../../../../common/SpeechToText/SpeechToText';
import CustomBox from '../../../../../common/CustomBox/CustomBox';
import LMMChatBotSwitch from '../../../../LMMChatBotSwitch/LMMChatBotSwitch';
interface props {
  handleValidePrompts(prompts: string): void;
  isRecording: boolean;
  stopRecording(): void;
  startRecording(): void;
  getRootProps: any;
  getInputProps: any;
  isSendMessageLoading: boolean;
  onSendForm(e: any): void;
  files: any;
  messageValue: string;
  onMouseEnterDropzone(): void;
  acceptFileInput?: boolean;
  onMouseLeaveDropzone(): void;
  handleTransciptAudio(word: string): void;
  isBotConfigMode?: boolean;
}
const PropsActions = ({
  handleValidePrompts,
  isRecording,
  stopRecording,
  startRecording,
  getRootProps,
  acceptFileInput,
  getInputProps,
  isSendMessageLoading,
  onSendForm,
  handleTransciptAudio,
  onMouseLeaveDropzone,
  onMouseEnterDropzone,
  messageValue,
  files,
  isBotConfigMode,
}: props) => {
  const theme = useTheme();
  const chatContext = React.useContext(ChatContext);
  const { data: dataSourceSession } = useGetChatSessionDataSource();
  const isInifiniteChat = chatContext?.isInifiniteChat;
  // Use memo to avoid re-rendering
  const shouldDisplayFileInput = React.useMemo(() => {
    if (isBotConfigMode) {
      return false;
    }
    if (acceptFileInput) {
      return true;
    }
    if (!isInifiniteChat && acceptFileInput) {
      return true;
    }

    return false;
  }, [isInifiniteChat, acceptFileInput, isBotConfigMode]);
  return (
    <Box display={'flex'} p={`0px ${theme.spacing(0.5)}`} width="100%">
      <Box
        alignItems="center"
        sx={{
          display: 'flex',
        }}
      >
        <Prompts handleValidePrompts={handleValidePrompts} />
        {/* {!isBotConfigMode ? (
          <CustomBox
            display="flex"
            sx={{
              ml: (theme) => theme.spacing(1),
              mt: (theme) => theme.spacing(1.5),
            }}
          >
            <LMMChatBotSwitch />
          </CustomBox>
        ) : null} */}
      </Box>

      <Box display="flex" alignItems="center" ml="auto">
        <Box
          display={{
            xs: 'none',
            sm: 'none',
            lg: 'block',
            md: 'block',
            xl: 'block',
          }}
        >
          {isRecording ? (
            <Box m="auto">
              <CustomTooltip
                arrow
                title={<FormattedMessage id="chat.form.record.audio.stop" />}
              >
                <IconButton
                  type="button"
                  onClick={stopRecording}
                  aria-label="stop-recording"
                >
                  <StopRecording />
                </IconButton>
              </CustomTooltip>
            </Box>
          ) : !isInifiniteChat && !isBotConfigMode ? (
            <Box m="auto">
              <CustomTooltip
                arrow
                title={<FormattedMessage id="chat.form.record.screen" />}
              >
                <IconButton
                  type="button"
                  onClick={startRecording}
                  aria-label="record-screen"
                >
                  <ScreenCastIcon />
                </IconButton>
              </CustomTooltip>
            </Box>
          ) : null}
        </Box>
        <Box mx={0.3}>
          {/* <RecordForm handleGetAudio={handleTransciptAudio} /> */}
          <SpeechToText />
        </Box>
        {shouldDisplayFileInput ? (
          <Box m="auto" {...getRootProps()}>
            <input {...getInputProps()} />
            {
              <CustomTooltip
                arrow
                title={<FormattedMessage id="chat.form.attach" />}
              >
                <IconButton
                  type="button"
                  aria-label="search"
                  onMouseEnter={onMouseEnterDropzone}
                  onMouseLeave={onMouseLeaveDropzone}
                  disabled={Boolean(
                    dataSourceSession?.getOneTodoChatSession?.allDataSources
                      ?.length
                  )}
                >
                  <PapperClipAttache />
                </IconButton>
              </CustomTooltip>
            }
          </Box>
        ) : null}

        <CustomTooltip
          arrow={true}
          title={
            !isSendMessageLoading ? (
              <FormattedMessage id="chat.form.send" />
            ) : (
              <FormattedMessage id="chat.form.send.progress" />
            )
          }
        >
          <Box
            onSubmit={onSendForm}
            onClick={onSendForm}
            sx={{
              alignItems: 'center',
              margin: '4px',
              borderRadius: '4px',
              background:
                messageValue.length || files.length ? '#3F404A' : '#00001F',
              height: 40,
              padding: '7px 16px 5px 16px',
              cursor: 'pointer',
              zIndex: 99999,
            }}
          >
            {isSendMessageLoading ? (
              <CircularProgress sx={{ color: 'white' }} size={20} />
            ) : (
              <SendIcon />
            )}
          </Box>
        </CustomTooltip>
      </Box>
    </Box>
  );
};
export default PropsActions;
