/* eslint-disable jsx-a11y/img-redundant-alt */
import { FileProps } from '../Attachments';
import STAudioControl from '../../../../../components/common/STAudioControl/STAudioControl';
interface props {
  item: FileProps;
}

export default function AudioItem({ item }: props) {
  return <STAudioControl src={item.url} name={item.title} />;
}
