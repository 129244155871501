import Avatar from '@mui/material/Avatar';
import CustomBox from '../CustomBox/CustomBox';
import UserAvatar from '../UserAvatar/UserAvatar';
import CustomTypography from '../CustomTypography/CustomTypography';

interface IProps {
  name: string;
  userProfil?: string | null;
}
const STBot: React.FC<IProps> = ({ name, userProfil }) => {
  return (
    <CustomBox
      sx={{
        display: 'flex',
      }}
    >
      <Avatar sx={{ width: 24, height: 24 }} variant="rounded">
        <UserAvatar userProfil={userProfil} />
      </Avatar>
      <CustomTypography sx={{ ml: (theme) => theme.spacing(1) }} variant="h6">
        {name}
      </CustomTypography>
    </CustomBox>
  );
};

export default STBot;
