// React FC component containing a material ui dialog with a input field for creating a new project

import * as React from 'react';
import { useMutation } from '@apollo/client';
import { graphql } from '../../../gql';
import { Box, styled } from '@mui/material';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import CustomButton from '../../common/CustomButton/CustomButton';
import { useIntl } from 'react-intl';
import AlertText from '../../common/AlertText/AlertText';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../../common/CustomDialog/CustomDialog';
import InviteCollaboratorContent from '../ProjectDashboard/ProjectDashboardHeader/ProjectCollaborators/ProjectInviteCollaborators/InviteCollaboratorContent/InviteCollaboratorContent';
import CustomBox from '../../common/CustomBox/CustomBox';
import Loading from '../../common/Loading/Loading';
import ProjectForm from './ProjectForm/ProjectForm';
import AddProjectButton from './AddProjectButton/AddProjectButton';

export const CREATE_PROJECT = graphql(`
  mutation CreateProject($newProjectData: NewProjectInput!) {
    createProject(newProjectData: $newProjectData) {
      id
      name
      currentViews {
        id
        name
        viewType {
          code
        }
      }
    }
  }
`);

export const BoxStyled = styled(Box)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: '14px',
    padding: '10px 8px',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    color: theme.palette.text.secondary,
    width: '100%',
  },

  '& .MuiOutlinedInput-root': {
    padding: '10px 0',
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
}));
interface props {
  isCompact?: boolean;
}
const AddProject = ({ isCompact }: props) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState<{
    projectName: string;
    projectDescription: string;
    shouldGenerateTasks: boolean;
  }>({ projectName: '', projectDescription: '', shouldGenerateTasks: true });
  const [isContinue, setIsContinue] = React.useState(false);
  // Prompt to generate task for the project
  const [projectPrompt, setProjectPrompt] = React.useState('');

  const [projectId, setProjectId] = React.useState() as any;
  const navigate = useNavigate();
  const intl = useIntl();
  const [createProject, { error, loading }] = useMutation(CREATE_PROJECT, {
    update(cache, { data }) {
      setProjectId(data?.createProject.id);
      setIsContinue(true);
      setProjectPrompt('');
      setValue({
        projectName: '',
        projectDescription: '',
        shouldGenerateTasks: true,
      });
      if (!data?.createProject) return;

      Mixpanel.track('Project created', {
        project: data?.createProject.name,
      });

      // Update myProjects cache query
      cache.modify({
        fields: {
          myProjects(existingProjects = []) {
            return [...existingProjects, data?.createProject];
          },
        },
      });
      navigate(`../project/${data?.createProject.id}`);
    },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsContinue(false);
    setValue({
      projectName: '',
      projectDescription: '',
      shouldGenerateTasks: true,
    });
  };

  const handleCreateProject = () => {
    if (value.projectName.trim()) {
      createProject({
        variables: {
          newProjectData: {
            name: value.projectName,
            initialPrompt: projectPrompt,
            description: value.projectDescription,
            shouldGenerateTasks: value.shouldGenerateTasks,
          },
        },
      });
    }
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      [e.currentTarget.name]:
        e.currentTarget.name == 'shouldGenerateTasks'
          ? e.currentTarget.checked
          : e.currentTarget.value,
    });
  };
  return (
    <>
      <AddProjectButton
        handleOpen={handleOpen}
        isCompact={isCompact as boolean}
      />
      {open ? (
        <CustomDialog
          open={open}
          handleClose={handleClose}
          disableCloseButton={loading}
          contentChildren={
            isContinue ? (
              <InviteCollaboratorContent projectId={projectId} />
            ) : (
              <BoxStyled>
                <Box mb={1} mt={1}>
                  {error && (
                    <AlertText
                      message={intl.formatMessage({
                        id: 'project.create.error',
                        defaultMessage: 'Error creating project',
                      })}
                      severity="error"
                    />
                  )}
                </Box>
                <ProjectForm
                  value={{
                    projectName: value.projectName,
                    projectDescription: value.projectDescription,
                    shouldGenerateTasks: value.shouldGenerateTasks,
                  }}
                  onChange={onChange}
                  isForAddProject={true}
                />

                {loading && value.shouldGenerateTasks && (
                  <CustomBox>
                    <CustomBox
                      sx={{
                        ml: 2,
                      }}
                    >
                      <Loading
                        size={20}
                        type="circular"
                        intlTextId="project.create.generating.tasks"
                        isWithText={true}
                      />
                    </CustomBox>
                  </CustomBox>
                )}
              </BoxStyled>
            )
          }
          actionChildren={
            <>
              {!isContinue ? (
                <>
                  <CustomButton
                    onClick={handleClose}
                    color="inherit"
                    label={intl.formatMessage({ id: 'project.button.cancel' })}
                  />
                  <CustomButton
                    disabled={loading || !value.projectName.trim()}
                    onClick={handleCreateProject}
                    variant="contained"
                    isLoading={loading}
                    label={intl.formatMessage({
                      id: 'project.button.continue',
                    })}
                  />
                </>
              ) : null}
            </>
          }
          title={
            isContinue
              ? intl.formatMessage({
                  id: 'project.invite.collaborator',
                  defaultMessage: 'Invite Collaborator',
                })
              : intl.formatMessage({
                  id: 'project.create.title',
                  defaultMessage: 'Create project',
                })
          }
        />
      ) : null}
    </>
  );
};

export default AddProject;
