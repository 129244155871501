import { useLocation, useParams } from 'react-router-dom';
import useCreateChatSession from './useCreateChatSession';
import useNavigateToChatSession from './useNavigateToChatSession';
import { graphql } from '../../../gql';
import { useQuery } from '@apollo/client';
import { getUrlParam } from '../../../utils/url';
export const GET_CHAT_SESSIONS = graphql(`
  query GetChatSessions($todoId: Float!, $limit: Int, $offset: Int) {
    getTodoChatSessions(todoId: $todoId, limit: $limit, offset: $offset) {
      id
      title
      isPrivate
      createdAt
      sessionUnreadNotificationsCount
      isTodoMainChat
      isCreatedByMe
      defaultBot {
        id
        user {
          profilePicture
          id
        }
      }
      dataSources {
        id
        name
        url
        fileType
      }
    }
  }
`);
const useGetAllChatSessions = ({ todoId }: { todoId: number }) => {
  const botId = getUrlParam('bot');
  const params = useParams();
  const location = useLocation();
  const { createChatSession } = useCreateChatSession({
    todoId,
  });
  const goToChatSession = useNavigateToChatSession();

  const { data, loading, error, fetchMore } = useQuery(GET_CHAT_SESSIONS, {
    variables: {
      todoId,
    },
    onCompleted: (data) => {
      if (
        data?.getTodoChatSessions?.length > 0 &&
        !params?.sessionId &&
        todoId !== 0
      ) {
        if (botId) {
          createChatSession({
            variables: {
              data: {
                todoId,
                defaultBotId: +(botId || 0),
              },
            },
          }).then((res) => {
            if (res?.data?.createTodoChatSession)
              goToChatSession({
                todoId,
                chatSessionId: res?.data?.createTodoChatSession?.id,
                dataSources: res?.data?.createTodoChatSession?.dataSources,
              });
            window.history.pushState({}, '', location.pathname);
          });
        } else {
          goToChatSession({
            todoId,
            chatSessionId: data?.getTodoChatSessions[0].id,
            dataSources: data?.getTodoChatSessions[0].dataSources,
          });
        }
      }
    },
  });

  return { data, loading, error, fetchMore };
};
export default useGetAllChatSessions;
