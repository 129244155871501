import { Grid } from '@mui/material';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Box from '@mui/system/Box';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material';
import CustomBox from '../../common/CustomBox/CustomBox';
import 'react-loading-skeleton/dist/skeleton.css';

const LeftContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: theme.spacing(3),
}));

const BodyLoading = () => {
  const theme = useTheme();
  const skeletonBaseColor =
    theme.palette.mode === 'dark' && theme.palette.background.default;
  const skeletonHighlightColor =
    theme.palette.mode === 'dark' && theme.palette.grey['400'];

  return (
    <SkeletonTheme
      baseColor={skeletonBaseColor}
      highlightColor={skeletonHighlightColor}
    >
      <LeftContainer>
        <Grid
          container={true}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item={true} xs={12}>
            <CommentLoader />
            <CommentLoader />
            <CommentLoader />
            <CommentLoader />
            <CommentLoader />
            <CommentLoader />
            <PromptLoader />
          </Grid>
        </Grid>
      </LeftContainer>
    </SkeletonTheme>
  );
};

const CommentLoader = () => (
  <CustomBox mt={2} mb={2}>
    <CustomBox
      sx={{ display: 'grid', gridTemplateColumns: '3% 97%', gap: '5px' }}
    >
      <CustomBox>
        <Skeleton height={30} />
      </CustomBox>
      <CustomBox sx={{ width: '25%' }}>
        <Skeleton height={30} />
      </CustomBox>
    </CustomBox>
    <CustomBox
      sx={{ display: 'grid', gridTemplateColumns: '3% 97%', gap: '5px' }}
    >
      <CustomBox />
      <CustomBox sx={{ width: '98%' }}>
        <Skeleton height={70} />
      </CustomBox>
    </CustomBox>
  </CustomBox>
);

const PromptLoader = () => (
  <CustomBox mt={2} mb={2}>
    <CustomBox
      sx={{ display: 'grid', gridTemplateColumns: '3% 97%', gap: '5px' }}
    >
      <CustomBox />
      <CustomBox sx={{ width: '98%' }}>
        <Skeleton height={60} />
      </CustomBox>
    </CustomBox>
  </CustomBox>
);

export default BodyLoading;
