import { useQuery } from '@apollo/client';
import { graphql } from '../gql';

const GET_ME = graphql(
  `
    query getUserMe {
      me {
        id
        firstName
        lastName
        email
        profilePicture
        referalCode
        profession
        birthday
        gender
        textToSpeechVoice
        isDisplayPersonalInfoPage
      }
    }
  `
);

const useGetMe = () => {
  const { loading, data, error, refetch } = useQuery(GET_ME, {
    fetchPolicy: 'cache-first',
  });

  const email = data?.me?.email || '';

  return { loading, data, error, refetch, email };
};

export default useGetMe;
