import * as React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';
import Loading from '../Loading/Loading';
import { FormattedMessage } from 'react-intl';

const ButtonStyle = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontFamily: 'Lato',
  boxShadow: 'none',
  fontSize: theme.typography.body2.fontSize,
  borderRadius: '10px',
  padding: '10px 10px',
  fontWeight: 'bold',
  lineHeight: 1.5,
  '&:hover': {
    boxShadow: 'none',
  },
}));

export interface CustomButtonProps extends ButtonProps {
  label?: React.ReactNode;
  isLoading?: Boolean;
  intlId?: string;
  intlValues?: any;
}
const CustomButton: React.FC<CustomButtonProps> = ({
  label,
  isLoading,
  onClick,
  intlId,
  children,
  intlValues,
  ...rest
}) => {
  if (children)
    return (
      <ButtonStyle onClick={onClick} {...rest}>
        {children}
        {isLoading ? <Loading type="circular" size={20} /> : null}
      </ButtonStyle>
    );

  return (
    <ButtonStyle onClick={onClick} {...rest}>
      {intlId ? (
        <FormattedMessage
          id={intlId}
          defaultMessage={label as string}
          values={intlValues}
        />
      ) : (
        label
      )}
      {isLoading ? <Loading type="circular" size={20} /> : null}
    </ButtonStyle>
  );
};

export default CustomButton;
