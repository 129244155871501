/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@apollo/client';
import { graphql } from '../../../../../gql';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CategoriePrompts from './CategoriePrompts/CategoriePrompts';
import { useContext, useEffect, useState } from 'react';
import { StepContext } from '../../../Prompts';
import { styled } from '@mui/material';
const StyledBox = styled(CustomBox)(({ theme }) => ({
  height: theme.spacing(58),
  overflowY: 'auto',
}));
export const GET_CATEGORIES_PROMPTS = graphql(`
  query GetCategoriesPrompts {
    getCategoriesPrompts {
      categoryGeneral {
        ...CategoryPromptsFragment
      }
      categoryByWorkspace {
        ...CategoryPromptsFragment
      }
    }
  }
`);
interface Category {
  id: number;
  title: string;
}

const SideBarPrompts = () => {
  const { data, loading } = useQuery(GET_CATEGORIES_PROMPTS);
  const { handleInitCategory } = useContext(StepContext);

  const categorieList = data?.getCategoriesPrompts;

  useEffect(() => {
    if (
      categorieList?.categoryGeneral &&
      categorieList?.categoryGeneral.length > 0
    ) {
      const categories = categorieList?.categoryGeneral as Category[];
      if (handleInitCategory) {
        // Find category with isCustomWorkspace true
        const customCategory = categories.find(
          (category: any) => category.isCustomWorkspace === true
        );

        // If customCategory is not null, then set it as the default category
        if (customCategory) {
          handleInitCategory(customCategory.id, customCategory.title);
        } else {
          handleInitCategory(categories[0].id, categories[0].title);
        }
      }
    }
  }, [categorieList]);

  return (
    <StyledBox pr={1.5}>
      <CategoriePrompts
        intlId="libraries.prompts.category.public"
        categories={categorieList?.categoryGeneral || []}
        loading={loading}
      />
      {/*  <CategoriePrompts
        intlId="libraries.prompts.category.workspaces"
        categories={categorieList?.categoryByWorkspace || []}
        hasInput={hasInput}
        hasPlusIcon={true}
        handleToogleShowInput={handleToogleShowInput}
        loading={loading}
      /> */}
    </StyledBox>
  );
};

export default SideBarPrompts;
