import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ListItem, MenuList, Tooltip, styled, useTheme } from '@mui/material';
import STInputBase from '../../../../common/STInputBase/STInputBase';
import CustomUnreadnotification from '../../../../common/CustomUnreadnotification/CustomUnreadnotification';
import BotAvatar from '../../../Description/Bots/Bot/BotTitleContent/BotAvatar/BotAvatar';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomTooltip from '../../../../common/CustomToolTip/CustomToolTip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useIntl } from 'react-intl';
import CustomMenu from '../../../../common/CustomMenu/CustomMenu';
import ItemContext from '../../../../common/CustomMenu/ItemContext/ItemContext';
import { Delete, PushPinOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { ChatContext } from '../../../Chat';
import useDisplayMessage from '../../../../common/GlobalMessage/useDisplayMessage';

interface Props {
  onClickSession: () => void;
  session: any;
  selectedItemId: number;
  isDisabledToDelete: boolean;
  handleUpdateSessionName(newSessionName: string): void;
  handleDeleteSessions(): void;
}

const MenuListStyled = styled(MenuList)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: '4px 0px 4px 0px',
}));
const SessionItem = ({
  onClickSession,
  session,
  selectedItemId,
  isDisabledToDelete,
  handleDeleteSessions,
  handleUpdateSessionName,
}: Props) => {
  const intl = useIntl();
  const theme = useTheme();
  const chatContext = React.useContext(ChatContext);
  const { displayMessage } = useDisplayMessage();
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const sessionDefaultTitle = session.title.replace(/<[^>]*>/g, '') || '';
  const [sessionValue, setSessionValue] = useState(sessionDefaultTitle);
  const [isRenameError, setIsRenameError] = useState(false);
  const isSessionDatasource =
    session.isTodoMainChat && !chatContext.isInifiniteChat;
  const handleChangeSession = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSessionValue(event.target.value);
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (sessionValue.trim().length) {
          handleUpdateSessionName(sessionValue.trim());
          setIsEditing(false);
        } else {
          displayMessage({
            type: 'error',
            message: 'update.session.error',
          });
          setIsRenameError(true);
        }
      }
    },
    [sessionValue, handleUpdateSessionName]
  );

  const handleBlur = useCallback(() => {
    if (sessionValue.trim().length) {
      handleUpdateSessionName(sessionValue.trim());
    } else {
      displayMessage({
        type: 'error',
        message: 'update.session.error',
      });
      setSessionValue(sessionDefaultTitle);
      setIsRenameError(false);
    }
    setIsEditing(false);
  }, [sessionValue, handleUpdateSessionName]);

  const handleClickMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleRenameClick = () => {
    handleCloseMenu();
    setIsEditing(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  const handleDeleteClick = () => {
    handleCloseMenu();
    handleDeleteSessions();
  };
  const open = Boolean(anchorEl);

  useEffect(() => {
    setSessionValue(sessionDefaultTitle);
  }, [sessionDefaultTitle]);
  return (
    <ListItem
      key={session.id}
      sx={{
        '&:hover': {
          backgroundColor: (theme: any) => theme.palette.customColor.hoverColor,
        },
        ...(selectedItemId === session.id && {
          backgroundColor: (theme: any) =>
            theme.palette.customColor.scrollColor,
        }),
        cursor: 'pointer',
        minHeight: '40px',
        borderRadius: 2,
        paddingY: 0,
        paddingRight: 1,
        position: 'relative',
        border:
          selectedItemId === session.id && isEditing && isRenameError
            ? `1px solid ${theme.palette.error.dark}`
            : selectedItemId === session.id && isEditing
            ? `1px solid ${theme.palette.grey[200]}`
            : '1px solid transparent',
      }}
      onClick={onClickSession}
    >
      {session.unreadNotificationsCount > 0 && (
        <CustomUnreadnotification sx={{ position: 'absolute', left: 2 }} />
      )}
      {isSessionDatasource ? (
        <CustomBox
          sx={{
            mr: theme.spacing(1),
            ml: theme.spacing(0.5),
            mt: theme.spacing(1),
          }}
        >
          <Tooltip
            arrow
            sx={{ width: 28, height: 28 }}
            title={intl.formatMessage({ id: 'todo.chat.main.pin' })}
          >
            <PushPinOutlined
              sx={{ color: theme.palette.grey[200] }}
              fontSize="small"
            />
          </Tooltip>
        </CustomBox>
      ) : (
        <CustomBox sx={{ mr: theme.spacing(0.5) }}>
          <BotAvatar userProfil={session?.defaultBot?.user?.profilePicture} />
        </CustomBox>
      )}
      <STInputBase
        id={`session-text-${session.id}`}
        value={sessionValue}
        inputProps={<input ref={inputRef} />}
        onKeyDown={handleKeyDown}
        onChange={handleChangeSession}
        onBlur={handleBlur}
        inputMode="text"
        readOnly={!isEditing}
        aria-multiline={false}
      />
      <CustomBox
        className="action-box"
        onClick={handleClickMenu}
        sx={{
          display: selectedItemId === session.id ? 'flex' : 'none',
        }}
      >
        <CustomTooltip
          arrow
          title={intl.formatMessage({ id: 'action.more.options' })}
        >
          <MoreHorizIcon fontSize="small" />
        </CustomTooltip>
      </CustomBox>
      <CustomMenu anchorEl={anchorEl} handleClose={handleCloseMenu} open={open}>
        <MenuListStyled>
          <ItemContext
            label="context.menu.rename.label"
            handleAction={handleRenameClick}
            defaultMessage="Rename"
          >
            <EditIcon fontSize="small" />
          </ItemContext>
          {!isSessionDatasource &&
            !isDisabledToDelete &&
            session.isCreatedByMe && (
              <ItemContext
                label="context.menu.delete"
                handleAction={handleDeleteClick}
                defaultMessage="Delete"
              >
                <Delete fontSize="small" />
              </ItemContext>
            )}
        </MenuListStyled>
      </CustomMenu>
    </ListItem>
  );
};

export default SessionItem;
