import * as React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';
import { useIntl } from 'react-intl';

interface CustomTooltipProps extends TooltipProps {
  intlId?: string;
  intlValues?: any;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  children,
  ...props
}) => {
  const intl = useIntl();
  const title = props.intlId
    ? intl.formatMessage({ id: props.intlId }, props.intlValues)
    : props.title;
  return (
    <Tooltip {...props} title={title}>
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
