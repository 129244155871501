import { PaletteMode } from '@mui/material';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    customColor: {
      chatDescriptionBackground: string;
      sidebarBodyBackground: string;
      bodyBackground: string;
      scrollColor: string;
      hoverColor: string;
      selectionColor: string;
      chatSessionBackground: string;
      chatBackground: string;
      messageBackground: string;
      messageBackgroundHover: string;
      projectSectionKabanBackground: string;
      taskKanbanBackgroundColor: string;
      taskBackgroundColor: string;
      yellowChartColor: string;
      yellowChartColorHover: string;
      editorBackgrounColor: string;
      whiteColor: string;
      grayColor: string;
    };
  }
  // allow configuration using `createTheme`
}

const theme = (mode: PaletteMode = 'light') => {
  return createTheme({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            customColor: {
              bodyBackground: '#F5F5F5',
              sidebarBodyBackground: '#E0E0E0',
              scrollColor: '#D9D9D9',
              hoverColor: '#EEEEEE',
              selectionColor: '#EEEEEE',
              chatSessionBackground: '#F7F7FC',
              chatBackground: '#F5F5F5',
              chatDescriptionBackground: '#F9F9FA',
              messageBackground: '#34324A0C',
              messageBackgroundHover: '#34324A1A',
              projectSectionKabanBackground: '#ECECED',
              taskKanbanBackgroundColor: '#FFFFFF',
              taskBackgroundColor: '#FFFFFF',
              yellowChartColor: '#F6AE2D',
              // Darker for hover
              yellowChartColorHover: '#D59424',
              editorBackgrounColor: '#F5F5F5',
              whiteColor: '#fff',
              grayColor: '#CDCDCD',
            },

            background: {
              default: '#F5F5F5',
              paper: '#F5F5F5',
            },
            primary: {
              main: '#34324A',
              dark: '#242333',
              light: '#5c5b6e',
              contrastText: '#E9F1F7',
            },
            secondary: {
              main: '#ff6e72',
              dark: '#b24d4f',
              light: '#ff8b8e',
              contrastText: '#E9F1F7',
            },
            grey: {
              '400': '#FFFFFF',
              '200': 'rgba(142, 142, 145, 1)',
              '100': '#F5F5F5',
            },
            divider: '#E2E3E9',
            text: {
              disabled: 'rgba(52, 50, 74, 1)',
              primary: '#1D1C1D',
            },
          }
        : {
            customColor: {
              bodyBackground: '#2A2B2F',
              sidebarBodyBackground: '#222327',
              scrollColor: '#FFFFFF1A',
              hoverColor: '#34373C',
              selectionColor: '#4E5058',
              chatSessionBackground: '#252529',
              chatBackground: '#2A2D31',
              chatDescriptionBackground: '#222327',
              messageBackground: '#222327',
              messageBackgroundHover: '#252529',
              projectSectionKabanBackground: '#24262C',
              taskKanbanBackgroundColor: '#2A2B2F',
              taskBackgroundColor: '#24262C',
              yellowChartColor: '#F6AE2D',
              yellowChartColorHover: '#D59424',
              editorBackgrounColor: '#222327',
              whiteColor: '#24262C',
              grayColor: '#34324A',
            },
            background: {
              default: '#222327',
              paper: '#24262C',
            },
            primary: {
              main: '#34324A',
              dark: '#24262C',
              light: '#1098F7',
              contrastText: '#E9F1F7',
            },
            secondary: {
              main: '#ff6e72',
              dark: '#2E4057',
              light: '#F4F1BB',
              contrastText: '#E9F1F7',
            },
            grey: {
              '200': 'rgba(142, 142, 145, 1)',
              '100': '#F5F5F5',
              '300': 'rgba(233, 241, 247, 0.5)',
              '400': '#2A2B2F',
            },
            divider: 'rgba(255, 255, 255, 0.1)',
            text: {
              disabled: 'rgba(52, 50, 74, 1)',
              primary: '#dbdee1',
              secondary: '#f2f3f5',
            },
          }),
    },

    typography: {
      fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, sans-serif',
      body1: {
        fontSize: 15,
        lineHeight: 1.5,
      },
      body2: {
        fontSize: 15,
        lineHeight: 1.5,
      },
      h1: {
        fontSize: 20,
        fontWeight: 900,
      },
      h2: {
        fontSize: 18,
        fontWeight: 900,
      },
      h3: {
        fontSize: 16,
        fontWeight: 700,
      },
      h4: {
        fontSize: 14,
        fontWeight: 900,
      },
      h5: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: 1.5,
      },
      h6: {
        fontSize: 14,
        fontWeight: 400,
      },
      caption: {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  });
};

export default theme;
