import { useMutation, useQuery } from '@apollo/client';
import useDisplayMessage from '../components/common/GlobalMessage/useDisplayMessage';
import { graphql } from '../gql/gql';
import { Mixpanel } from '../mixpanel/mixpanel';
import { useContext } from 'react';
import { StepContext } from '../components/Prompts/Prompts';

export const GET_PROMPTS = graphql(`
  query GetCategoryPrompts($categoryId: Float!) {
    getPromptsByCategory(categoryId: $categoryId) {
      id
      description
      title
      fieldsPrompts
      createdAt
    }
  }
`);

const CREATE_PROMPT = graphql(`
  mutation CreatePrompt($newPromptInput: NewPromptInput!) {
    createPrompt(NewPromptInput: $newPromptInput) {
      id
      fieldsPrompts
      title
      description
    }
  }
`);
const DELETE_PROMPT = graphql(`
  mutation DeletePrompt($promptId: Float!) {
    deletePrompt(PromptId: $promptId) {
      id
      title
      removed
    }
  }
`);
const UPDATE_PROMPT = graphql(`
  mutation UpdatePrompt($updatePromptInput: UpdatePromptInput!) {
    updatePrompt(UpdatePromptInput: $updatePromptInput) {
      id
      title
      description
    }
  }
`);

const useGetCategoryPrompts = (categoryId: number) => {
  const { idCategorie, updateStep } = useContext(StepContext);
  const { displayMessage } = useDisplayMessage();
  const { data, loading, error } = useQuery(GET_PROMPTS, {
    variables: { categoryId },
  });
  const [deletePrompt] = useMutation(DELETE_PROMPT);
  const [updatePrompt] = useMutation(UPDATE_PROMPT);
  const onUpdatePrompt = (
    description: string,
    promptId: number,
    title: string
  ) => {
    updatePrompt({
      variables: {
        updatePromptInput: {
          description,
          promptId,
          title,
        },
      },
      update() {
        updateStep({
          activeStep: 0,
          lastStep: 2,
          idPrompts: 0,
          categoryName: '',
          idCategorie: idCategorie,
          updateStep,
          handleNextStep: () => {},
          handlePreviousStep: () => {},
          isCustomCategory: true,
        });
        displayMessage({
          message: 'prompt.update.success',
          type: 'success',
        });
      },
    });
  };
  const onDeletePrompt = (promptId: number) => {
    deletePrompt({
      variables: {
        promptId,
      },
      update(cache, { data }) {
        displayMessage({
          message: 'prompt.delete.success',
          type: 'success',
        });
        const dataCache = cache.readQuery({
          query: GET_PROMPTS,
          variables: {
            categoryId,
          },
        });
        const deletedPrompt = data?.deletePrompt;
        const dataFiltered: any = dataCache?.getPromptsByCategory?.filter(
          (prompt) => prompt.id != deletedPrompt?.id
        );
        const newData = {
          getPromptsByCategory: [...dataFiltered],
        };
        cache.writeQuery({
          query: GET_PROMPTS,
          variables: {
            categoryId,
          },
          data: newData,
        });
      },
    });
  };

  const [addNewPrompt, { loading: loadingAddPrompt }] = useMutation(
    CREATE_PROMPT,
    {
      onError(err) {
        displayMessage({
          type: 'success',
          message: 'create.prompt.error',
        });
      },
      update(cache, { data }) {
        displayMessage({
          type: 'success',
          message: 'create.prompt.success',
        });

        // Read query and write our cache
        if (!data) return;
        Mixpanel.track('Create custom prompt', {
          title: data?.createPrompt.title,
        });
        const dataCache: any = cache.readQuery({
          query: GET_PROMPTS,
          variables: {
            categoryId,
          },
        });

        const newPrompt = data?.createPrompt;
        const newData = {
          getPromptsByCategory: [newPrompt, ...dataCache.getPromptsByCategory],
        };
        cache.writeQuery({
          query: GET_PROMPTS,
          variables: {
            categoryId,
          },
          data: newData,
        });
      },
    }
  );

  return {
    data,
    loading,
    error,
    onUpdatePrompt,
    addNewPrompt,
    onDeletePrompt,
    loadingAddPrompt,
  };
};

export default useGetCategoryPrompts;
