import { Box } from '@mui/material';
import CustomButton from '../../../common/CustomButton/CustomButton';
import { useContext, useState } from 'react';
import { StepContext } from '../../Prompts';
import CustomBox from '../../../common/CustomBox/CustomBox';
import BoxField from './BoxField/BoxField';
import { useLocation, useParams } from 'react-router-dom';
import useGetCategoryPrompts from '../../../../Hooks/useGetCategoryPrompts';


const CustomPromtsStep2 = () => {
  const { idCategorie, updateStep } = useContext(StepContext);
  const { addNewPrompt, loadingAddPrompt } = useGetCategoryPrompts(idCategorie);
  const { pathname } = useLocation();
  const { workspaceId } = useParams();
  const [prompts, setPrompts] = useState({
    title: '',
    description: '',
    categoryPromptsId: idCategorie,
    workspaceId: +(workspaceId || 0),
  });

  const handleFieldChange = (event: any) => {
    setPrompts({
      ...prompts,
      [event.target.name]: event.target.value,
    });
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    if (prompts.categoryPromptsId && prompts.description && prompts.title) {
      addNewPrompt({
        variables: {
          newPromptInput: {
            ...prompts,
          },
        },
      }).then((res) => {
        updateStep({
          activeStep: 0,
          lastStep: 2,
          idPrompts: 0,
          categoryName: '',
          idCategorie: prompts.categoryPromptsId,
          updateStep,
          handleNextStep: () => {},
          handlePreviousStep: () => {},
          isCustomCategory: true,
        });
      });
    }
  };

  return (
    <CustomBox width="100%">
      <CustomBox p={3} width="100%" display={'flex'}>
        <CustomBox width="100%">
          <BoxField prompts={prompts} handleChange={handleFieldChange} />
        </CustomBox>
      </CustomBox>
      <Box justifyContent="flex-end" display="flex" pb={2}>
        <CustomButton
          intlId="libraries.prompts.save"
          type="submit"
          sx={{ mr: 2 }}
          variant="contained"
          isLoading={loadingAddPrompt}
          disabled={(!prompts.title || !prompts.description) as boolean}
          onClick={handleSave}
        />
      </Box>
    </CustomBox>
  );
};

export default CustomPromtsStep2;
