import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';
import { lazy } from 'react';
import LazyBoundary from '../components/LazyBoundary/LazyBoundary';
import SignIn from '../components/Auth/Signin/Signin';
import SignUp from '../components/Auth/Signup/Signup';
import ForgotPassword from '../components/Auth/ForgotPassword/ForgotPassword';
import ProtectedRoute from '../components/common/ProtectedRoute/ProtectedRoute';
import ResetPassword from '../components/Auth/ResetPassword/ResetPassword';
import GoogleAuth from '../components/Auth/GoogleAuth/GoogleAuth';
import EmailConfirmation from '../components/EmailConfirmation/EmailConfirmation';
import EmailConfirm from '../components/EmailConfirmation/EmailConfirm/EmailConfirm';
import MainIndexPage from '../components/MainIndexPage/MainIndexPage';
import ChatLayout from '../components/Chat/ChatLayout';
import Loader from '../components/LazyBoundary/Loader/Loader';

const Chat = lazy(() => import('../components/Chat/ChatContainer'));
const Layout = lazy(() => import('../components/Layout/Layout'));
const SettingLayout = lazy(
  () => import('../components/Setting/SettingLayout/SettingLayout')
);
const ProjectListPage = lazy(
  () => import('../components/ProjectListPage/ProjectListPage')
);
const AllTasks = lazy(() => import('../components/AllTasks/AllTasks'));
const Account = lazy(() => import('../components/Setting/Account/Account'));
const WorkspaceNotFound = lazy(
  () => import('../components/Workspace/WorkspaceNotFound/WorkspaceNotFound')
);
const WorkspaceHome = lazy(
  () => import('../components/WorkspaceHome/WorkspaceHome')
);
const UpComing = lazy(() => import('../components/UpComing/UpComing'));
const ProjectDashboard = lazy(
  () => import('../components/Project/ProjectDashboard/ProjectDashboard')
);
const NotFound = lazy(() => import('../components/NotFound/NotFound'));
const MyBotListPage = lazy(
  () => import('../components/MyBotListPage/MyBotListPage')
);
const CustomBotPage = lazy(
  () => import('../components/Chat/CustomBotPage/CustomBotPage')
);
const Talk = lazy(() => import('../components/Talk/Talk'));
const CreditPage = lazy(() => import('../components/CreditPage/CreditPage'));
const DataSourcesPage = lazy(
  () => import('../components/DataSourcesPage/DataSourcesPage')
);
const WorkspaceSetting = lazy(
  () => import('../components/Workspace/WorkspaceSetting/WorkspaceSetting')
);
const NewDataSourcesPage = lazy(
  () =>
    import(
      '../components/DataSourcesPage/NewDataSourcesPage/NewDataSourcesPage'
    )
);
const DataSourceDetail = lazy(
  () =>
    import('../components/DataSourcesPage/DataSourceDetail/DataSourceDetail')
);
const PersonalInfo = lazy(
  () => import('../components/PersonalInfo/PersonalInfo')
);
const WorkSpaceSettingPage = lazy(
  () => import('../components/Workspace/WorkSpaceSettingPage')
);

const router = createBrowserRouter([
  {
    path: '/not-found-workspace',
    element: (
      <ProtectedRoute>
        <WorkspaceNotFound />
      </ProtectedRoute>
    ),
  },
  {
    path: '/',
    index: true,
    element: <MainIndexPage />,
  },
  {
    path: 'personal-info',
    element: (
      <ProtectedRoute>
        <PersonalInfo />
      </ProtectedRoute>
    ),
  },
  {
    path: '/:workspaceId',
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'admin',
        element: (
          <ProtectedRoute>
            <WorkSpaceSettingPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'setting',
        element: (
          <ProtectedRoute>
            <WorkspaceSetting />
          </ProtectedRoute>
        ),
      },
      {
        path: 'project/:projectID',
        element: (
          <ProtectedRoute>
            <ProjectDashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: 'project-list',
        element: (
          <ProtectedRoute>
            <ProjectListPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'mybots',
        element: (
          <ProtectedRoute>
            <MyBotListPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'credit',
        element: (
          <ProtectedRoute>
            <CreditPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'datasources',
        element: (
          <ProtectedRoute>
            <DataSourcesPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'datasources/detail/:datasourceID',
        element: (
          <ProtectedRoute>
            <DataSourceDetail />
          </ProtectedRoute>
        ),
      },
      {
        path: 'datasources/new',
        element: (
          <ProtectedRoute>
            <NewDataSourcesPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'bot/:botId',
        element: (
          <ProtectedRoute>
            <CustomBotPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'all-my-tasks',
        element: (
          <ProtectedRoute>
            <AllTasks />
          </ProtectedRoute>
        ),
      },
      {
        index: true,
        element: (
          <ProtectedRoute>
            <WorkspaceHome />
          </ProtectedRoute>
        ),
      },
      {
        path: 'profil/',
        element: (
          <ProtectedRoute>
            <SettingLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: 'account',
            element: (
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'chat/:taskID',
        element: (
          <ProtectedRoute>
            <ChatLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: ':sessionId?',
            element: (
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'up-coming',
        element: (
          <ProtectedRoute>
            <UpComing />
          </ProtectedRoute>
        ),
      },
      {
        path: 'talk',
        element: (
          <ProtectedRoute>
            <ChatLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: ':sessionId?',
            element: (
              <ProtectedRoute>
                <Talk />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/signin',
    element: (
      <ProtectedRoute isPublic>
        <SignIn />
      </ProtectedRoute>
    ),
  },
  {
    path: '/auth/google',
    element: (
      <ProtectedRoute isPublic>
        <GoogleAuth />
      </ProtectedRoute>
    ),
  },
  {
    path: '/confirm-email-request',
    element: (
      <ProtectedRoute>
        <EmailConfirmation />
      </ProtectedRoute>
    ),
  },
  {
    path: '/confirm-email/:token',
    element: (
      <ProtectedRoute>
        <EmailConfirm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/signup',
    element: (
      <ProtectedRoute isPublic>
        <SignUp />
      </ProtectedRoute>
    ),
  },
  {
    path: '/forgot',
    element: (
      <ProtectedRoute isPublic>
        <ForgotPassword />
      </ProtectedRoute>
    ),
  },
  {
    path: '/reset/:token',
    element: <ResetPassword />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default function Router() {
  return (
    <LazyBoundary isPrintError={true} size={30} loadingComponente={<Loader />}>
      <RouterProvider router={router} />
    </LazyBoundary>
  );
}
