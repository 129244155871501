import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import CustomBox from '../../common/CustomBox/CustomBox';
import SearchBotDialog from './SearchBotDialog/SearchBotDialog';
import CustomTypography from '../../common/CustomTypography/CustomTypography';
import Search from '@mui/icons-material/Search';
import CustomButton from '../../common/CustomButton/CustomButton';
import { Mixpanel } from '../../../mixpanel/mixpanel';

interface IProps {
  todoId: number;
  triggerComponent?: React.ReactNode;
  shouldCreateSession?: boolean;
}

const SearchBot = ({
  todoId,
  triggerComponent,
  shouldCreateSession,
}: IProps) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => {
    Mixpanel.track('Open Search Bot Dialog', {
      todoId: todoId,
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <CustomBox
        onClick={handleOpenDialog}
        sx={{
          width: '100%',
        }}
      >
        {triggerComponent ? (
          triggerComponent
        ) : (
          <CustomBox
            sx={{
              diplay: 'flex',

              //backgroundColor: (theme) => theme.palette.grey[200],
              borderRadius: (theme) => theme.spacing(0.5),
              width: '100%',
            }}
          >
            <CustomButton startIcon={<Search />}>
              <CustomTypography fontWeight="bold">
                <FormattedMessage id="bot.explore.more" />
              </CustomTypography>
            </CustomButton>
          </CustomBox>
        )}
      </CustomBox>
      {openDialog ? (
        <SearchBotDialog
          todoId={todoId}
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          shouldCreateSession={shouldCreateSession}
        />
      ) : null}
    </>
  );
};
export default SearchBot;
