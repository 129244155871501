import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChatContext } from '../Chat';

const useNavigateToChatSession = () => {
  const navigate = useNavigate();
  const params = useParams();
  const chatContext = React.useContext(ChatContext);
  const goToChatSession = ({
    chatSessionId,
    todoId,
    dataSources,
  }: {
    chatSessionId: number;
    todoId?: number;
    dataSources?: any;
  }) => {
    const path = chatContext?.isInifiniteChat
      ? `/${params.workspaceId}/talk/${chatSessionId}`
      : `/${params.workspaceId}/chat/${todoId}/${chatSessionId}`;
    navigate(path);
  };
  return goToChatSession;
};

export default useNavigateToChatSession;
