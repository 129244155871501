import { Box } from '@mui/material';
import CustomTypography from '../../../../../common/CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';

interface props {
  categoryName: string;
  descriptionIntl?: string;
}
const HeadPrompts = ({ categoryName, descriptionIntl }: props) => {
  return (
    <>
      <Box>
        {categoryName ? (
          <CustomTypography variant="h3">{categoryName}</CustomTypography>
        ) : (
          <CustomTypography variant="h3">
            <FormattedMessage id="libraries.prompts.popular.template" />
          </CustomTypography>
        )}
      </Box>
      {descriptionIntl && (
        <Box pt={1.5}>
          <CustomTypography variant="caption" color="CaptionText">
            <FormattedMessage id={descriptionIntl} />
          </CustomTypography>
        </Box>
      )}
    </>
  );
};

export default HeadPrompts;
