import { Grid } from '@mui/material';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Box from '@mui/system/Box';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: theme.spacing(3),
}));

export const ProviderSkeleton = ({ children }) => {
  const theme = useTheme();
  const skeletonBaseColor =
    theme.palette.mode === 'dark' && theme.palette.background.default;
  const skeletonHighlightColor =
    theme.palette.mode === 'dark' && theme.palette.grey['400'];

  return (
    <SkeletonTheme
      baseColor={skeletonBaseColor}
      highlightColor={skeletonHighlightColor}
    >
      <Container>
        <Grid item={true} xs={12}>
          {children}
        </Grid>
      </Container>
    </SkeletonTheme>
  );
};

export const SkeletonContent = Skeleton;
