import { Divider, styled, useTheme } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage, useIntl } from 'react-intl';
import useGetMe from '../../../../Hooks/useGetMe';
import { Mixpanel } from '../../../../mixpanel/mixpanel';
import CustomBox from '../../../common/CustomBox/CustomBox';
import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import useDisplayMessage from '../../../common/GlobalMessage/useDisplayMessage';
import Header from '../AccountContent/Header/Header';
import ReferralBotCredits from './ReferralBotCredits/ReferralBotCredits';
import ShareLinkToSocialMedia from './ShareLinkToSocialMedia/ShareLinkToSocialMedia';
const BoxLink = styled(CustomBox)(({ theme }) => ({
  width: '80%',
  height: 40,
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
  padding: '7px 8px',
  border: `1px solid ${theme.palette.grey[200]}`,
}));
const BoxButton = styled(CustomButton)(({ theme }) => ({
  minWidth: '15%',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  height: 40,
}));
const ReferralContent = () => {
  const { data } = useGetMe();

  const { displayMessage } = useDisplayMessage();
  const theme = useTheme();
  const intl = useIntl();
  const linkToCopy = `https://www.app.swiftask.ai/signup?invite=${data?.me.referalCode}`;
  const handleCopyLink = async () => {
    displayMessage({
      type: 'success',
      message: 'account.referral.copy.link.success',
    });
    Mixpanel.track('Copy referral link', {
      page: 'Account',
    });
  };
  

  return (
    <CustomBox
      bgcolor={theme.palette.background.default}
      width={'100%'}
      borderRadius={1}
    >
      <CustomBox p={1}>
        <CustomBox>
          <Header
            isSubHeader={true}
            title={<FormattedMessage id="account.referral.title" />}
          />
          <CustomBox p={1}>
            <CustomTypography
              sx={{
                lineHeight: '1.5rem',
                wordSpacing: '0.1rem',
                letterSpacing: '0.05rem',
              }}
            >
              <FormattedMessage id="account.referral.description" />

              {/*   <Link sx={{ color: 'blue' }}>
                <FormattedMessage id="account.referral.upgrade" />
              </Link> */}
            </CustomTypography>

            <CustomBox mt={2}>
              <CustomTypography>
                <FormattedMessage id="account.referral.link" />
                <CustomBox display="flex" mt={1} width="100%">
                  <BoxLink>
                    <CustomTypography>{linkToCopy}</CustomTypography>
                  </BoxLink>
                  <CopyToClipboard text={linkToCopy} onCopy={handleCopyLink}>
                    <BoxButton
                      label={intl.formatMessage({
                        id: 'account.referral.button.copy.link',
                      })}
                      color={'secondary'}
                      variant="contained"
                    />
                  </CopyToClipboard>
                </CustomBox>
              </CustomTypography>
            </CustomBox>
            <CustomBox mt={2}>
              <Divider sx={{ mt: 2 }}>
                <FormattedMessage id="account.referral.more" />
              </Divider>
              <CustomBox mt={2} display="flex" width={'100%'}>
                <ShareLinkToSocialMedia link={linkToCopy} />
              </CustomBox>
            </CustomBox>
           {/* <CustomBox mt={2}>
              <ReferralBotCredits />
            </CustomBox>*/}
          </CustomBox>
        </CustomBox>
      </CustomBox>
    </CustomBox>
  );
};
export default ReferralContent;
