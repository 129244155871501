import * as React from 'react';
import { useParams } from 'react-router-dom';
import CustomBox from '../../common/CustomBox/CustomBox';
import CustomButton from '../../common/CustomButton/CustomButton';
import {
  ProviderSkeleton,
  SkeletonContent,
} from '../../common/Skeleton/Skeleton';
import useNavigateToChatSession from '../hooks/useNavigateToChatSession';
import { styled } from '@mui/material';
import { useIntl } from 'react-intl';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import useCreateChatSession from '../hooks/useCreateChatSession';
import useChatSession from '../hooks/useChatSession';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Add from '@mui/icons-material/Add';
import useGetAllChatSessions from '../hooks/useGetAllChatSessions';
import useDeleteSession from '../hooks/useDeleteSession';
import SessionGrouped from './SessionGrouped/SessionGrouped';

const StyledButton = styled(CustomButton)(({ theme }) => ({
  height: theme.spacing(3.5),

  color: theme.palette.grey[200],
  '&:hover': {
    color: theme.palette.text.primary,
    borderColor: theme.palette.grey[200],
  },
}));
interface IProps {
  todoId: number;
  onChatSessionChange?: (chatSessionId: number) => void;
}

const ChatSession: React.FC<IProps> = ({ todoId, onChatSessionChange }) => {
  const { data, loading, error, fetchMore } = useGetAllChatSessions({ todoId });
  const { handleDeleteSession } = useDeleteSession({ todoId });
  const [showMoreSessions, setShowMoreSessions] = React.useState(false);
  const goToChatSession = useNavigateToChatSession();
  const intl = useIntl();
  const params = useParams();
  const { createChatSession, loadingNewSession } = useCreateChatSession({
    todoId: todoId as number,
    onCompleted: (data) => {
      Mixpanel.track('Create chat session', {
        'Session title': data?.createTodoChatSession?.title,
      });
      // Go to chat session
      onItemClick(data?.createTodoChatSession);
    },
  });

  const { updateChatSession } = useChatSession();
  const sessionId = params?.sessionId ? +params?.sessionId : 0;
  const handleUpdateSessionName = React.useCallback(
    (value: string) => {
      updateChatSession({
        variables: {
          id: sessionId,
          data: {
            title: value,
          },
        },
      });
    },
    [sessionId, updateChatSession]
  );

  const onClick = () => {
    setShowMoreSessions(false);
    createChatSession({
      variables: {
        data: {
          todoId,
        },
      },
    });
  };

  const onItemClick = (item: any) => {
    goToChatSession({
      todoId,
      chatSessionId: item.id,
      dataSources: item.dataSources,
    });
    if (onChatSessionChange) {
      onChatSessionChange(item.id);
    }
  };

  if (loading) {
    return (
      <CustomBox
        sx={{
          overflowY: 'auto',
          maxHeight: '80vh',
          width: (theme) => theme.spacing(25),
        }}
      >
        <ProviderSkeleton>
          <CustomBox mt={1} mb={1}>
            <SkeletonContent height={35} />
          </CustomBox>
          <CustomBox mt={1} mb={1}>
            <SkeletonContent height={35} />
          </CustomBox>
          <CustomBox mt={1} mb={1}>
            <SkeletonContent height={35} />
          </CustomBox>
          <CustomBox mt={1} mb={1}>
            <SkeletonContent height={35} />
          </CustomBox>
        </ProviderSkeleton>
      </CustomBox>
    );
  }

  if (error) {
    return null;
  }
  const sessions = data?.getTodoChatSessions || [];

  const handleViewMoreClick = () => {
    setShowMoreSessions(true);
    fetchMore({
      variables: {
        offset: sessions.length,
        limit: 1000,
      },
      updateQuery: (prev: any, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          getTodoChatSessions: [
            ...prev.getTodoChatSessions,
            ...fetchMoreResult.getTodoChatSessions,
          ],
        });
      },
    });
  };
  const handleViewLessClick = () => {
    setShowMoreSessions(false);
    //refetch({

    //});
  };
  const renderViewToggleButton = () => {
    if (sessions.length > 4) {
      if (!showMoreSessions) {
        return (
          <StyledButton
            color="primary"
            variant="text"
            onClick={handleViewMoreClick}
            label={intl.formatMessage({
              id: 'button.session.view.more',
            })}
            startIcon={<KeyboardArrowDownIcon />}
          />
        );
      } else {
        return (
          <StyledButton
            color="primary"
            variant="outlined"
            sx={{ position: 'absolute', bottom: 0, width: '92%' }}
            onClick={handleViewLessClick}
            fullWidth
            label={intl.formatMessage({
              id: 'button.session.view.less',
            })}
            startIcon={<KeyboardArrowUpIcon />}
          />
        );
      }
    }
    return null;
  };
  return (
    <CustomBox>
      <CustomBox
        sx={{
          overflowY: 'auto',
          maxHeight: '80vh',
          width: (theme) => theme.spacing(25),
        }}
      >
        <CustomBox>
          <CustomButton
            intlId="chat.newSession"
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            onClick={onClick}
            sx={{
              height: (theme) => theme.spacing(4.5),
            }}
            isLoading={loadingNewSession}
            disabled={loadingNewSession}
            startIcon={<Add />}
          />
        </CustomBox>
        <SessionGrouped
          sessions={sessions}
          onClickSession={onItemClick}
          selectedItemId={sessionId}
          handleUpdateSessionName={handleUpdateSessionName}
          groupBy={showMoreSessions ? 'createdAt' : undefined}
          handleDeleteSessions={handleDeleteSession}
        />
      </CustomBox>
      {renderViewToggleButton()}
    </CustomBox>
  );
};

export default ChatSession;
