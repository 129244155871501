import CustomBox from '../../../../common/CustomBox/CustomBox';
import ListBotsCategory from '../../../SearchBot/SearchBotDialog/ListBotByTags/ListBotByTags';

interface props {
  todoId: number;
}
const AiStore = ({ todoId }: props) => {
  return (
    <CustomBox
      sx={{
        width: '80%',
        margin: 'auto',
        pb: (theme) => theme.spacing(2),
      }}
    >
      <ListBotsCategory
        isDisplaySearch={false}
        isDisplayBotsTag={true}
        todoId={todoId}
        sx={{
          mt: (theme) => theme.spacing(1),
          maxHeight: '53vh',
          overflowY: 'auto',
        }}
      />
    </CustomBox>
  );
};
export default AiStore;
