import { styled } from '@mui/material';
import CustomBox from '../../../common/CustomBox/CustomBox';
import HeadMessage from './HeadMessage';

interface HeaderProps {
  showHeader: boolean;
}
const HeadMessageContainer = styled(CustomBox)<HeaderProps>(
  ({ theme, showHeader }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: `0px ${theme.spacing(0.5)}`,
    //borderBottomLeftRadius: '5px',
    //borderBottomRightRadius: '5px',
    transition: 'transform 0.1s ease, opacity 0.1s ease',
    transform: showHeader ? 'translateY(0)' : 'translateY(-100%)',
    opacity: 1,
    zIndex: 999,
    background: theme.palette.background.default,
  })
);
const HeadContainer = () => {
  return (
    <HeadMessageContainer showHeader={true}>
      <HeadMessage />
    </HeadMessageContainer>
  );
};
export default HeadContainer;
