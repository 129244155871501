import { FC } from 'react';
import CustomBox from '../CustomBox/CustomBox';
import { useDropzone } from 'react-dropzone';
import useCreateFile, { FileType } from '../../../Hooks/useCreateFile';
import STButton from '../STButton/STButton';
import CustomTypography from '../CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import STProgressCircular from '../STProgressCircular/STProgressCircular';
import Loading from '../Loading/Loading';
import { UploadFileOutlined } from '@mui/icons-material';
import config from '../../../config/config';
import useDisplayMessage from '../GlobalMessage/useDisplayMessage';
import { useTheme } from '@mui/material';

interface IProps {
  onAddFiles(files: FileType[]): void;
  multiple?: boolean;
  supportedFiletypes?: string;
  loading?: boolean;
  fileFormPlaceholder?: string | null;
  size?: 'large' | 'small';
  isDisplayLoader?: boolean;
  isDirectlyToUpload?: boolean;
  maxFileSize?: number;
  values?: FileType[];
}

const STFileForm: FC<IProps> = ({
  onAddFiles,
  multiple,
  supportedFiletypes,
  isDisplayLoader,
  fileFormPlaceholder,
  size = 'small',
  loading: isLoadingInParent,
  isDirectlyToUpload = true,
  maxFileSize,
  values,
}) => {
  const {
    uploadProgress,
    handleCreateFile,
    isInsupported,
    loading,
    files,
    onDropFile,
  } = useCreateFile({
    onComplete: (files: FileType[]) => {
      onAddFiles(files);
    },
    supportedFiletypes,
    isDirectlyToUpload,
  });

  const { displayMessage } = useDisplayMessage();
  const theme = useTheme();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFile,
    multiple,
    disabled: loading || isLoadingInParent,
    maxSize: maxFileSize || config.maxFileSize,
    onDropRejected: (fileRejections: any) => {
      displayMessage({
        message: 'file.too.large',
        type: 'error',
      });
    },
  });
  const onClickUpload = () => {
    handleCreateFile();
  };

  return (
    <CustomBox>
      <CustomBox
        {...getRootProps()}
        sx={{
          // drag and drop file sx
          border: `1px dashed ${
            isInsupported ? theme.palette.error.main : theme.palette.grey[200]
          }`,
          borderRadius: '10px',
          cursor: 'pointer',
          padding: (theme) => theme.spacing(1),
          textAlign: 'center',
          width: '100%',
          height: size === 'large' ? '200px' : 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <input {...getInputProps()} />
        <CustomBox>
          {uploadProgress === 0 && !isLoadingInParent && (
            <CustomBox>
              <CustomBox>
                <UploadFileOutlined />
              </CustomBox>

              <CustomTypography variant="caption">
                <FormattedMessage
                  id={fileFormPlaceholder || 'fileForm.dropfileIndication'}
                />
              </CustomTypography>
            </CustomBox>
          )}
          {isInsupported && (
            <CustomTypography
              sx={{
                color: theme.palette.error.main,
                fontSize: '14px',
              }}
            >
              <FormattedMessage id={'unsupported.file.label'} />
            </CustomTypography>
          )}
          {isDisplayLoader && uploadProgress > 0 && !isLoadingInParent && (
            <CustomBox>
              <CustomBox>
                <STProgressCircular
                  sx={{ color: (theme) => theme.palette.grey[200] }}
                  size={50}
                  value={uploadProgress}
                />
              </CustomBox>
              <CustomBox>
                <CustomTypography variant="caption">
                  <FormattedMessage
                    id="fileForm.uploading"
                    defaultMessage="Uploading"
                  />
                </CustomTypography>
              </CustomBox>
            </CustomBox>
          )}
          {isLoadingInParent || loading ? (
            <CustomBox>
              <CustomBox>
                <Loading
                  sx={{ color: (theme) => theme.palette.grey[200] }}
                  type="circular"
                  size={50}
                />
              </CustomBox>
              <CustomBox>
                <CustomTypography variant="caption">
                  <FormattedMessage
                    id="fileForm.analyzing"
                    defaultMessage="Analyzing"
                  />
                </CustomTypography>
              </CustomBox>
            </CustomBox>
          ) : null}
        </CustomBox>
      </CustomBox>
      <>
        {values && files.length > 0 && (
          <>
            <CustomBox>
              <CustomBox>Files</CustomBox>
              <CustomBox>
                {files.map((file, index) => (
                  <CustomBox key={index}>{file.name}</CustomBox>
                ))}
              </CustomBox>
            </CustomBox>
            {!isDirectlyToUpload && !isLoadingInParent && (
              <CustomBox
                sx={{
                  marginTop: (theme) => theme.spacing(1),
                }}
              >
                <STButton
                  fullWidth
                  sx={{
                    bgcolor:
                      files.length > 0
                        ? theme.palette.primary.main
                        : 'transparent',
                  }}
                  variant="outlined"
                  intlId="fileForm.upload"
                  onClick={onClickUpload}
                  disabled={loading || isLoadingInParent || !files.length}
                />
              </CustomBox>
            )}
          </>
        )}
      </>
    </CustomBox>
  );
};

export default STFileForm;
