const config = {
  GRAPHQL_URL:
    process.env.REACT_APP_GRAPHQL_URL || 'http://localhost:4000/graphql',
  GRAPHQL_WS_URL:
    process.env.REACT_APP_GRAPHQL_WS_URL || 'ws://localhost:4000/graphql',
  CLOUD_NAME: process.env.CLOUD_NAME || 'dsht8f3ff',
  UPLOAD_PRESET: process.env.UPLOAD_PRESET || 'rzsodtj7',
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '',
  OAUTH_GOOGLE_CLIENT_ID: process.env.REACT_APP_OAUTH_GOOGLE_CLIENT_ID || '',
  PRISMIC_API_ENDPOINT:
    process.env.PRISMIC_API_ENDPOINT || 'https://swiftask.prismic.io/api/v2',
  PRISMIC_ACCESS_TOKEN: process.env.PRISMIC_ACCESS_TOKEN,
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
    appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
  },
  firebaseVapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY || '',
  reactAppServerLessEndPoint: process.env.REACT_APP_SERVERLESS_END_POINT || '',
  logRocketId: process.env.REACT_APP_LOG_ROCKET_ID || '',
  maxFileSize: 50 * 1024 * 1024, // 50MB,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI || '',
  },
  microsoft: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID || '',
  },
  slug: {
    rag: 'new_rag',
  },
  REACT_APP_MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN || '',
};
export default config;
