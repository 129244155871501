export const getNativatorLanguage = (): string => {
  const supportedLanguages = {
    fr: true,
    'fr-FR': true,
    'fr-BE': true,
    'fr-CA': true,
    'fr-CH': true,
    'fr-LU': true,
    'fr-MC': true,
    'fr-RE': true,
    'fr-WF': true,
    'fr-YT': true,
    'fr-BJ': true,
    'fr-BF': true,
    'fr-BI': true,
    'fr-CM': true,
    'fr-CF': true,
    'fr-CI': true,
    'fr-CG': true,
    'fr-CO': true,
    'fr-DJ': true,
    'en-US': true,
    en: true,
    'en-GB': true,
    'en-AU': true,
    'en-CA': true,
    'en-NZ': true,
    'en-ZA': true,
    'en-IE': true,
    'en-IN': true,
    'en-BZ': true,
    'en-JM': true,
    'en-BW': true,
    'en-PH': true,
    'en-ZW': true,
    'en-TT': true,
    'en-ZM': true,
    'en-MY': true,
    'en-SG': true,
  };
  let locale: string =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    'en-US';

  // If not supported return 'en-US'

  if (!supportedLanguages[locale as keyof typeof supportedLanguages]) {
    locale = 'en-US';
  }

  // remove region code
  locale = locale.split('-')[0];

  return locale;
};
