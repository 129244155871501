import * as React from 'react';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import Bot from '../Bot/Bot';
interface IProps {
  bots: any;
  onBotClick: (bot: any) => void;
  todoId: number;
  categoryTitle: string;
  displayCreateBot?: boolean;
  isNew?: boolean;
}

const BotsList: React.FC<IProps> = ({
  onBotClick,
  bots,
  todoId,
  categoryTitle,
  displayCreateBot = false,
  isNew = false,
}) => {
  return (
    <CustomBox>
      <CustomBox
        mt={(theme) => theme.spacing(1)}
        sx={{
          paddingLeft: (theme) => theme.spacing(1.5),
        }}
      >
        <CustomBox>
          <CustomBox
            sx={{
              ml: (theme) => theme.spacing(0.5),
              fontSize: '12px',
              mb: (theme) => theme.spacing(1),
            }}
          >
            {isNew ? (
              <CustomTypography
                sx={{
                  ...(isNew
                    ? {
                        color: (theme) => theme.palette.secondary.main,
                        fontWeight: 'bold',
                      }
                    : {}),
                }}
              >
                {categoryTitle}!
              </CustomTypography>
            ) : (
              <CustomTypography>{categoryTitle}</CustomTypography>
            )}
          </CustomBox>

          {bots.map((bot: any) => (
            <CustomBox
              sx={{
                pl: (theme) => theme.spacing(0.5),
                pr: (theme) => theme.spacing(0.5),
                // On hover
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.background.paper,
                  borderRadius: '4px',
                },
              }}
            >
              <Bot
                botSlug={bot.slug}
                botname={bot.name}
                botWorkspaceId={bot.workspaceId}
                isForDescription={true}
                avatar={bot.user?.profilePicture}
                botDescription={bot.description}
                onClick={() => onBotClick(bot)}
                isBot={false}
                botId={bot.id}
                key={bot.id}
                isOpenDocumentationOnClicked={true}
                openEditDialog={() => {}}
              />
            </CustomBox>
          ))}
        </CustomBox>
      </CustomBox>
    </CustomBox>
  );
};
export default BotsList;
