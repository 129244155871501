import React from 'react';
import { Mixpanel } from '../../mixpanel/mixpanel';
import ChatContainerLayout from './ChatContainerLayout/ChatContainerLayout';

interface IProps {
  todoId: number;
}

export const ChatContext = React.createContext<{
  isInifiniteChat: boolean;
  extraConfig: { [key: string]: any };
  onChangeExtraConfig: (name: string, value: string) => void;
}>({
  isInifiniteChat: false,
  extraConfig: {},
  onChangeExtraConfig: () => {
    console.warn('setExtraConfig function is not initialized');
  },
});

const Chat: React.FC<IProps> = ({ todoId }) => {
  React.useEffect(() => {
    Mixpanel.track('Chat page view', {
      todoId,
    });
  }, [todoId]);

  return <ChatContainerLayout todoId={todoId} />;
};

export default React.memo(Chat);
