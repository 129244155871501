import SideBarPrompts from './SideBarPrompts/SideBarPrompts';
import ContainerPrompts from './ContainerPrompts/ContainerPrompts';
import { Box, Divider, styled } from '@mui/material';
import CustomBox from '../../../common/CustomBox/CustomBox';

const StyledBox = styled(CustomBox)(({ theme }) => ({
  height: theme.spacing(58),
  overflow:"auto"
}));

interface props {
  handleSelectedPrompt(id: number): void;
}
const PromptsStepOne = ({ handleSelectedPrompt }: props) => {
  return (
    <Box display={'flex'} width={'100%'}>
      <Box  my={1} py={1} px={2} width={'25%'} >
        <SideBarPrompts />
      </Box>
      <Divider orientation="vertical" flexItem />
      <StyledBox  my={1} pb={1} px={3} width={'74%'}>
        <ContainerPrompts handleSelectedPrompt={handleSelectedPrompt} />
      </StyledBox>
    </Box>
  );
};

export default PromptsStepOne;
