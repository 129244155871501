import { Box } from '@mui/material';
import CustomButton from '../../../common/CustomButton/CustomButton';
import { useContext, useState } from 'react';
import { StepContext } from '../../Prompts';
import CustomBox from '../../../common/CustomBox/CustomBox';
import { useParams } from 'react-router-dom';
import useGetCategoryPrompts from '../../../../Hooks/useGetCategoryPrompts';
import BoxField from '../CustomPromtsStep2/BoxField/BoxField';
import useGetOnePrompt from '../../../../Hooks/useGetOnePrompt';
import React from 'react';

const UpdateCustomPrompt = () => {
  const { idCategorie, idPrompts } = useContext(StepContext);
  const { onUpdatePrompt, loadingAddPrompt } =
    useGetCategoryPrompts(idCategorie);
  const { workspaceId } = useParams();
  const { data } = useGetOnePrompt();
  const prompt = data?.findFirstPromptsOrThrow;
  const defaultDescription = prompt?.description as string;
  const defaultTitle = prompt?.title as string;
  const [prompts, setPrompts] = useState<{
    title: string;
    description: string;
    categoryPromptsId: number;
    workspaceId: number;
  }>({
    title: '',
    description: '',
    categoryPromptsId: idCategorie,
    workspaceId: +(workspaceId || 0),
  });

  const handleFieldChange = (event: any) => {
    setPrompts({
      ...prompts,
      [event.target.name]: event.target.value,
    });
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (prompts.categoryPromptsId && prompts.description && prompts.title) {
      onUpdatePrompt(prompts.description, idPrompts, prompts.title);
    }
  };
  React.useEffect(() => {
    setPrompts({
      title: defaultTitle,
      description: defaultDescription,
      categoryPromptsId: idCategorie,
      workspaceId: +(workspaceId || 0),
    });
  }, [defaultDescription, defaultTitle]);
  return (
    <CustomBox width="100%">
      <CustomBox p={3} width="100%" display={'flex'}>
        <CustomBox width="100%">
          <BoxField prompts={prompts} handleChange={handleFieldChange} />
        </CustomBox>
      </CustomBox>
      <Box justifyContent="flex-end" display="flex" pb={2}>
        <CustomButton
          intlId="libraries.prompts.save.change"
          sx={{ mr: 2 }}
          variant="contained"
          isLoading={loadingAddPrompt}
          disabled={
            defaultTitle == prompts.title &&
            defaultDescription == prompts.description
          }
          onClick={handleSave}
        />
      </Box>
    </CustomBox>
  );
};

export default UpdateCustomPrompt;
