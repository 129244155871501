import { gql, useApolloClient, useQuery } from '@apollo/client';
import { AlertColor } from '@mui/material';

const GET_GLOBAL_MESSAGE = gql`
  query getGlobalMessage {
    globalMessage @client {
      type
      message
    }
  }
`;
interface GlobalMessage {
  type: AlertColor;
  message: string;
}
// The hook return a function to display the message
const useDisplayMessage = () => {
  const client = useApolloClient();
  const { data } = useQuery(GET_GLOBAL_MESSAGE);
  const displayMessage = ({ type, message }: GlobalMessage) => {
    client.writeQuery({
      query: GET_GLOBAL_MESSAGE,
      data: {
        globalMessage: {
          type,
          message,

          __typename: 'GlobalMessage',
        },
      },
    });
  };
  const clearMessage = () => {
    client.writeQuery({
      query: GET_GLOBAL_MESSAGE,
      data: {
        globalMessage: {
          type: 'SUCCESS',
          message: '',

          __typename: 'GlobalMessage',
        },
      },
    });
  };

  const getMessage = (): GlobalMessage => {
    const data = client.readQuery({
      query: GET_GLOBAL_MESSAGE,
    });
    return data?.globalMessage;
  };
  const message = data?.globalMessage;

  return { displayMessage, clearMessage, getMessage, message };
};

export default useDisplayMessage;
