import { useQuery } from '@apollo/client';
import { graphql } from '../../../gql';
import React from 'react';
import { useParams } from 'react-router-dom';
import { scrollToTheBottom } from '../utils/scrollUtils';

export const GET_CHAT = graphql(`
  query sessionMessages($sessionId: Float!) {
    sessionMessages(sessionId: $sessionId) {
      ...ChatMessageFragment
    }
  }
`);

const useGetChatTodo = ({
  todoId,
  sessionId,
}: {
  todoId: number;
  sessionId?: number;
}) => {
  const messagesEndRef = React.createRef<any>();
  const divRef = React.useRef<HTMLDivElement>(null);
  const [messageSkip, setMessageSkip] = React.useState(0);
  const params = useParams();

  let theSessionId = sessionId || +(params?.sessionId ? params.sessionId : 0);

  const { data, error, loading, refetch } = useQuery(GET_CHAT, {
    fetchPolicy: 'cache-first',
    variables: {
      sessionId: theSessionId,
    },
    onCompleted: (data) => {
      if (data.sessionMessages.length) {
        setMessageSkip(data.sessionMessages.length);
      }
    },
  });
  React.useEffect(() => {
    refetch();
  }, [params?.sessionId]);

  React.useEffect(() => {
    if (messagesEndRef.current) {
      // Si c'est le premier chargement des messages
      if (messageSkip === data?.sessionMessages?.length) {
        scrollToTheBottom(messagesEndRef);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesEndRef.current, data]);

  return {
    divRef,
    refetch,
    data,
    error,
    messagesEndRef,
    loading,
    currentSessionId: theSessionId,
  };
};

export default useGetChatTodo;
