import * as React from 'react';

const SendIcon = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.32263 19.878L19.7516 13.179C20.7496 12.716 20.7496 11.282 19.7516 10.819L5.32263 4.12199C4.28763 3.64199 3.19263 4.65999 3.57963 5.74299L5.81263 11.997L3.57963 18.258C3.19263 19.34 4.28763 20.358 5.32263 19.878Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.80957 12H20.4996" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
}

export default SendIcon 