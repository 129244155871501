import { useCallback } from 'react';
import { ContentType } from '../../../gql/graphql';
import { ChatContext } from '../Chat';
import React from 'react';

interface UseDropFilesProps {
  contentTypes: ContentType[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setErrorMessageDropFiles: React.Dispatch<React.SetStateAction<string>>;
}
const useDropFiles = (props: UseDropFilesProps) => {
  const { contentTypes, setFiles, setErrorMessageDropFiles } = props;
  const chatContext = React.useContext(ChatContext);
  const formatContentTypes = () => {
    return contentTypes
      .map((contentType) => `${contentType.type}(${contentType.extension})`)
      .join(', ');
  };
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (chatContext.isInifiniteChat) {
        const isAcceptedFilesType = acceptedFiles?.map((a) =>
          contentTypes?.map((b) => a.type === b.type)
        );
        const containsAllFalse = isAcceptedFilesType.some((a) =>
        a.every((b) => b === false)
      );
        if (containsAllFalse) {
          setFiles([]);
          setErrorMessageDropFiles(formatContentTypes);
        } else {
          setErrorMessageDropFiles('');
          setFiles(
            acceptedFiles.map((file: File) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
        }
      } else {
        setFiles(
          acceptedFiles.map((file: File) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      }
    },
    [contentTypes, setFiles]
  );
  return { onDrop };
};
export default useDropFiles;
