import { Box, styled } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import STTextField from '../../../../common/STTextField/STTextField';

import CustomBox from '../../../../common/CustomBox/CustomBox';
const CustomContainer = styled(Box)(({ theme }) => ({
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none',
  },
  '&:hover .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '&:hover .MuiInput-underline:after': {
    borderBottom: 'none',
  },
  '& .MuiInputBase-root': {
    background: theme.palette.customColor.bodyBackground,
    borderRadius: 4,
    marginBottom: 10,
    borderBottom: 'none',
    padding: '5px 10px',
  },
  width: '100%',
}));
interface Prompt {
  title: string;
  description: string;
  categoryPromptsId: number;
}

interface PromptProps {
  prompts: Prompt;
  handleChange(event: any): void;
}
const BoxField = ({
  prompts,
  handleChange,
}: PromptProps) => {
  const intl = useIntl();

  return (
    <>
      <CustomContainer>
        <CustomTypography fontWeight={'bold'} variant="h3" my={1}>
          <FormattedMessage id="prompt.library.add.title.field" />
        </CustomTypography>
        <STTextField
          value={prompts.title}
          name="title"
          autoFocus
          id="title-prompts"
          fullWidth
          onChange={handleChange}
        />
        <CustomBox mt={1}>
          <CustomTypography fontWeight={'bold'} variant="h3" my={1}>
            <FormattedMessage id="prompt.library.add.description.field" />
          </CustomTypography>
          <STTextField
            id="description-prompts"
            multiline
            value={prompts.description}
            placeholder={intl.formatMessage({ id: 'symbol.#.description' })}
            name="description"
            onChange={handleChange}
            fullWidth
            required
            rows={10}
          />
        </CustomBox>
      </CustomContainer>
    </>
  );
};

export default BoxField;
