import { styled, useTheme } from '@mui/material';
import Moment from 'react-moment';
import UserAvatar from '../../../common/UserAvatar/UserAvatar';
import CustomBox from '../../../common/CustomBox/CustomBox';
import { FormattedMessage } from 'react-intl';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import useGetTodoActivity from '../../hooks/useGetTodoActivity';

const AvatarWrapper = styled(CustomBox)(({ theme }) => ({
  marginRight: '1px',
}));

const ContentWrapper = styled(CustomBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const Description = styled(CustomBox)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  fontSize: '12px',
  color: theme.palette.text.primary,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  paddingRight: theme.spacing(1),
  opacity: 1,
  marginRight: theme.spacing(2),
  textOverflow: 'ellipsis',
  flex: 1,
}));

const Timestamp = styled(CustomBox)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '10px',
  marginLeft: theme.spacing(0.5),
  padding: `0 ${theme.spacing(2)}  0 0`,
}));

const BoxWrapper = styled(CustomBox)(({ theme }) => ({
  height: 'auto',
  boxShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',
  //padding: `${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  width: '100%',
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
}));

const TodoActivity = ({ todoId }: { todoId: number }) => {
  const { data } = useGetTodoActivity({ todoId });
  const theme = useTheme();
  const activities = data?.todo.todoActivities || [];
  return (
    <CustomBox>
      {activities.length ? (
        <CustomBox my={2}>
          <CustomTypography variant="h6" fontWeight="700">
            <FormattedMessage id="todo.activity.title" />
          </CustomTypography>
        </CustomBox>
      ) : null}
      {activities.length ? (
        activities.map((value:any) => (
          <BoxWrapper key={value.id}>
            <ContentWrapper>
              <AvatarWrapper>
                <UserAvatar
                  alt="User"
                  userProfil={value.user?.profilePicture}
                  sx={{ width: 30, height: 30 }}
                />
              </AvatarWrapper>
              <CustomBox width={'100%'}>
                <Description>
                  <b>{value.user?.firstName}</b> <span>{value.action}</span>
                  {value.mentionedUser && (
                    <>
                      <span>
                        <FormattedMessage id="activity.mention.to" />
                      </span>
                      <b>{value.mentionedUser?.firstName}</b>
                    </>
                  )}
                </Description>
                <Timestamp>
                  <Moment fromNow>{value.createdAt}</Moment>
                </Timestamp>
              </CustomBox>
            </ContentWrapper>
          </BoxWrapper>
        ))
      ) : (
        <Description display={'flex'}>
          <CustomTypography
            sx={{
              margin: 'auto',
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
            }}
          >
            <FormattedMessage id="activity.empty" />
          </CustomTypography>
        </Description>
      )}
    </CustomBox>
  );
};

export default TodoActivity;
