import { useQuery } from '@apollo/client';
import { graphql } from '../gql/gql';

export const GET_BOTS_BY_TAG = graphql(
  `
    query GetBotsByTag($tagSlug: String, $searchQuery: String, $limit: Float) {
      getSearchBots(
        tagSlug: $tagSlug
        searchQuery: $searchQuery
        limit: $limit
      ) {
        id
        name
        slug
        description
        workspaceId
        createdAt
        baseBot {
          id
          name
          slug
          description
          createdAt
          user {
            id
            firstName
            lastName
            profilePicture
          }
        }
        user {
          id
          firstName
          lastName
          profilePicture
        }
      }
    }
  `
);

const useGetBotsByTag = ({
  tag: tagSlug,
  limit = 10,
}: {
  tag: string;
  limit?: number;
}) => {
  const { data, error, loading } = useQuery(GET_BOTS_BY_TAG, {
    variables: { tagSlug, limit },
  });

  const bots = data?.getSearchBots || [];
  return {
    bots,
    error,
    loading,
  };
};

export default useGetBotsByTag;
