import * as React from 'react';
import { withErrorBoundary, useErrorBoundary } from 'react-use-error-boundary';
import { Button, Grid } from '@mui/material';
import CustomTypography from '../../common/CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import CustomBox from '../../common/CustomBox/CustomBox';

interface props {
  children: React.ReactNode;
  isPrintError: Boolean;
}
const ErrorBoundary = withErrorBoundary(({ children, isPrintError }: props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, resetError] = useErrorBoundary((error, errorInfo) =>
    console.log(error, errorInfo)
  );

  const handleReload = () => {
    window.location.reload();
  };

  if (error) {
    if (isPrintError) {
      return (
        <Grid container justifyContent={'center'}>
          <Grid md={6} item>
            <CustomBox
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '50vh',
              }}
            >
              <CustomTypography
                variant="h4"
                style={{
                  marginBottom: '16px',
                }}
              >
                <FormattedMessage
                  id="global.error.message"
                  defaultMessage="An error has occurred, please try again, if the problem persists, please contact us."
                />
              </CustomTypography>

              <Button
                variant="contained"
                color="secondary"
                onClick={handleReload}
              >
                <FormattedMessage id="common.reload" defaultMessage="Reload" />
              </Button>
            </CustomBox>
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
});

export default ErrorBoundary;
