import React, { useContext } from 'react';
import PromptsStepOne from './PrompsStepOne/PromptsStepOne';
import PromptsStepTwo from './PromptsStepTwo/PromptsStepTwo';
import { Box, styled } from '@mui/material';
import CustomPromtsStep2 from './CustomPromtsStep2/CustomPromtsStep2';
import { StepContext } from '../Prompts';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import UpdateCustomPrompt from './UpdateCustomPrompt/UpdateCustomPrompt';
interface props {
  handleValidePrompts(prompts: string): void;
}

const CustomBoxContainer = styled(Box)(({ theme }) => ({
  padding: '0px 0px 5px 0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default function BodyPrompts({ handleValidePrompts }: props) {
  const { activeStep, handleNextStep } = useContext(StepContext);

  const handleSelectedPrompt = (id: number) => {
    Mixpanel.track('Prompts - Select Prompt', { id: id });
    if (id) {
      handleNextStep(null, 1, id);
    }
  };

  return (
    <CustomBoxContainer>
      <>
        {activeStep === 0 ? (
          <PromptsStepOne handleSelectedPrompt={handleSelectedPrompt} />
        ) : activeStep === 1 ? (
          <PromptsStepTwo callback={handleValidePrompts} />
        ) : activeStep === 2 ? (
          <CustomPromtsStep2 />
        ) : activeStep === 3 ? (
          <UpdateCustomPrompt />
        ) : null}
      </>
    </CustomBoxContainer>
  );
}
