import { isEmpty } from 'lodash';

export const isStringStartsWith = (str: string, prefix: string) => {
  return str.indexOf(prefix) === 0;
};

export const isEmptyString = (str: string | null | undefined) => {
  if (str === null || str === undefined) {
    return true;
  }
  str = str.trim();
  // Remove all the white spaces
  str = str.replace(/\s/g, '');
  // Remove all the new lines
  str = str.replace(/\n/g, '');
  return isEmpty(str);
};
