import { Box } from '@mui/material';
import CustomBox from '../../../common/CustomBox/CustomBox';
import ChatAutoReply from './ChatAutoReply/ChatAutoReply';
import DefaultChatLMMBot from './DefaultChatLMMBot/DefaultChatLMMBot';
import { ChatContext } from '../../Chat';
import { useContext } from 'react';
import Link from '@mui/material/Link';
import { useParams } from 'react-router-dom';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CustomIconButton from '../../../common/CustomIconButton/CustomIconButton';
import ProjectBreadCumb from './ProjectBreadCumb/ProjectBreadCumb';
import ResetChatButton from './ResetChatButton/ResetChatButton';
import useCustomBotConfig from '../../../../Hooks/useCustomBotConfig';
import useGetChatContext from '../ChatBodyContainer/useGetChatContext';
import STStartChatBotButton from '../../../common/STStartChatBotButton/STStartChatBotButton';

const HeadMessage = () => {
  const chatContextValue = useContext(ChatContext);
  const { sessionId, botId } = useParams();
  const botID = +(botId || 0);
  const { isBotConfigMode } = useGetChatContext();
  const { customBotConfig } = useCustomBotConfig({ botId: botID });
  const mainChatSessionId = customBotConfig?.botConfigMainChatSessionId;
  return (
    <CustomBox display={'flex'} alignItems={'center'}>
      {!chatContextValue.isInifiniteChat && <ProjectBreadCumb />}
      <Box flexGrow={1} />
      <ResetChatButton
        chatSessionId={
          +((!isBotConfigMode ? sessionId : mainChatSessionId) || 0)
        }
      />
      {isBotConfigMode && <STStartChatBotButton botId={botID} />}
      {!isBotConfigMode && (
        <>
          <CustomBox sx={{ display: ['none', 'block'] }}>
            <Link
              href={`https://graphql.swiftask.ai/chat/export/${sessionId}`}
              download="text.md"
            >
              <CustomIconButton
                sx={{ mr: (theme) => theme.spacing(1) }}
                intlId={'export.chat'}
                size="small"
              >
                <SaveAltIcon fontSize="small" />
              </CustomIconButton>
            </Link>
          </CustomBox>
          {!chatContextValue.isInifiniteChat && (
            <CustomBox
              sx={{
                display: ['none', 'block'],
                width: '25px',
                position: 'relative',
                '& span': {
                  fontSize: '12px',
                },
              }}
            >
              <ChatAutoReply />
            </CustomBox>
          )}

          <CustomBox
            sx={{
              '& span': {
                fontSize: '12px',
              },
            }}
          >
            <DefaultChatLMMBot />
          </CustomBox>
        </>
      )}
    </CustomBox>
  );
};
export default HeadMessage;
