import { useQuery } from '@apollo/client';
import { graphql } from '../gql';

export const BOTS_QUERY = graphql(
  `
    query GetBots($isChatLMM: Boolean) {
      bots(isChatLMM: $isChatLMM) {
        id
        name
        canBeExtendedToChildBots
        slug
        description
        user {
          id
          firstName
          lastName
          profilePicture
        }
        illustrationUrl
        isDefaultChatLMM
        isChatLMM
        stripePaymentLink
        purchasePageOrder
        creditUnitPrice
        creditAmountPerUnitPrice
        quantityLabel
        workspaceId
        quantityLabelFR
      }
    }
  `
);

const useGetBots = ({ isChatLMM }: { isChatLMM?: boolean }) => {
  const { data, error, loading } = useQuery(BOTS_QUERY, {
    variables: {
      isChatLMM: isChatLMM || false,
    },
    fetchPolicy: 'cache-and-network',
  });
  return { data, error, loading };
};

export default useGetBots;
