import { useMediaQuery } from '@mui/material';
import React from 'react';

const useChatLayoutSize = () => {
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const defaultChatWidth = isSm ? '100%' : '60%';
  const [leftSize, setLeftSize] = React.useState(defaultChatWidth);
  const onResizeStop = (e: any, direction: any, ref: any, d: any) => {
    setLeftSize(ref.offsetWidth);
  };
  React.useEffect(() => {
    setLeftSize(defaultChatWidth);
  }, [defaultChatWidth]);

  return { chatWidth: leftSize, onResizeStop, isSm };
};

export default useChatLayoutSize;
