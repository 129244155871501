import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CustomTypography from '../../../../../../common/CustomTypography/CustomTypography';
import { styled, useTheme } from '@mui/material';
import CustomBox from '../../../../../../common/CustomBox/CustomBox';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CustomIconButton from '../../../../../../common/CustomIconButton/CustomIconButton';
import useConfirmDialog from '../../../../../../../Hooks/useConfirmDialog';
import { StepContext } from '../../../../../Prompts';
interface props {
  id: number;
  title: string;
  isSelected: boolean;
  isForCustomPrompt?:boolean;
  description?: string | null;
  handleClick(id: number): void;
  onDeletePrompt(id:number):void
}

const TypographyStyled = styled(CustomTypography)(({ theme }) => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 4,
}));

export default function CardPrompts({
  id,
  title,
  description,
  isForCustomPrompt,
  isSelected,
  handleClick,
  onDeletePrompt,
}: props) {
  const { handleNextStep } = React.useContext(StepContext);
  const theme = useTheme();
  const { displayConfirmDialog } = useConfirmDialog();
  const handelDeletePrompt = () => {
    displayConfirmDialog({
      message: 'prompt.delete.alert.description',
      onConfirm: () => {
        onDeletePrompt(id)
      },
    });
  };
  const handleUpdatePrompt=()=>{
    if (id) {
      handleNextStep(null, 3, id);
    }
  }
  const colorText = isSelected
    ? theme.palette.primary.main
    : theme.palette.text.secondary;
  return (
    <Card
      sx={{
        maxWidth: 345,
        cursor: 'pointer',
        backgroundColor: isSelected
          ? theme.palette.secondary.light
          : theme.palette.background.paper,
      }}
      
    >
      <CardContent>
        <CustomBox display={'flex'} width='100%'>
        <CustomTypography onClick={() => handleClick(id)} sx={{ color: colorText }} gutterBottom variant="h5">
          {title}
        </CustomTypography>
       {isForCustomPrompt&& <CustomBox display={'flex'} ml={'auto'}>
          <CustomIconButton intlId={''} size='small' onClick={handleUpdatePrompt}>
              <DriveFileRenameOutlineIcon fontSize='small'/>
            </CustomIconButton>
            <CustomIconButton intlId={''} size='small' onClick={handelDeletePrompt}>
              <DeleteOutlineIcon fontSize='small'/>
            </CustomIconButton>
        </CustomBox>}
        </CustomBox>
        <TypographyStyled onClick={() => handleClick(id)} noWrap sx={{ color: colorText }} variant="body2">
          {description}
        </TypographyStyled>
      </CardContent>
    </Card>
  );
}
