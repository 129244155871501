import { useState } from 'react';

const useExtraConfig = () => {
  const [extraConfig, setExtraConfig] = useState({});

  const onChangeExtraConfig = (name: string, value: any) => {
    setExtraConfig((lasted) => ({
      ...lasted,
      [name]: value,
    }));
  };

  return {
    extraConfig,
    onChangeExtraConfig,
  };
};

export default useExtraConfig;
