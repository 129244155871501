import CustomBox from '../../../../../../common/CustomBox/CustomBox';
import CustomButton from '../../../../../../common/CustomButton/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import ListPrompts from '../ListPrompts';

interface props {
  idCategorie: number;
  handleClickNewCustomPrompt(): void;
  handleSelectedPrompt(id: number): void;
}
const CustomPromtsStep1 = ({
  idCategorie,
  handleClickNewCustomPrompt,
  handleSelectedPrompt,
}: props) => {
  return (
    <>
      <CustomBox my={1} pr={1} display="flex" mb={1}>
        <CustomBox flexGrow={1} />
        <CustomButton
          intlId="button.create.custom.prompt"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          size="small"
          onClick={handleClickNewCustomPrompt}
        />
      </CustomBox>
      <ListPrompts
        handleClick={handleSelectedPrompt}
        idCategorie={idCategorie}
        isForCustomPrompt={true}
      />
    </>
  );
};
export default CustomPromtsStep1;
