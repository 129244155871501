import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { graphql } from '../../../gql';
import { Bot } from '../../../gql/graphql';

const GET_UI_LAYOUT_FORM_FIELD = graphql(`
  query GetUiLayout($sessionId: Float!) {
    getOneTodoChatSession(sessionId: $sessionId) {
      id
      defaultBot {
        id
        name
        formFields {
          formName
          descriptionFR
          description
          label
          labelFR
          placeholder
          placeholderFR
          rangeMax
          rangeMin
          type
          order
          defaultValue
          supportedFiletypes
          options {
            label
            description
            value
          }
        }
        uiLayout
      }
    }
  }
`);

const useChatLayoutFormField = () => {
  const params = useParams();
  const { data, loading } = useQuery(GET_UI_LAYOUT_FORM_FIELD, {
    variables: {
      sessionId: +(params?.sessionId || 0),
    },
  });
  const defaultBot = data?.getOneTodoChatSession?.defaultBot || ({} as Bot);
  return {
    data,
    loading,
    defaultBot,
  };
};

export default useChatLayoutFormField;
