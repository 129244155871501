/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Box, Grid, styled, useTheme } from '@mui/material';
import { graphql } from '../../../../gql';
import { useQuery } from '@apollo/client';
import scrollStyle from '../../../Chat/scrollStyle';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import CustomButton from '../../../common/CustomButton/CustomButton';
import Loading from '../../../common/Loading/Loading';
import { StepContext } from '../../Prompts';
import EditTagContent from './EditTagContent/EditTagContent';
import {
  arrayToObject,
  getRandomColor,
  onChangeDescriptionFromTag,
  removeTags,
  replaceTagName,
  spanTagName,
} from '../../../../utils/promptUtil';
import DescriptionPreview from './DescriptionPreview/DescriptionPreview';
import useGetOnePrompt from '../../../../Hooks/useGetOnePrompt';
interface props {
  callback(prompts: string): void;
}

const CustomGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(60),
  overflow: 'auto',
}));

const CustomContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

const CustomGridListField = styled(Grid)(({ theme }) => ({
  height: theme.spacing(60),
  overflow: 'auto',
}));

const PromptsStepTwo = ({ callback }: props) => {
  const { data } =useGetOnePrompt()

  const prompts = data?.findFirstPromptsOrThrow;

  const fields = prompts?.fieldsPrompts || [];
  const defaultDescription = prompts?.description as string;
  const [description, setDescription] = React.useState('');
  const [colors, setColors] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const theme = useTheme();

  const [formPromps, setFormPromps] = React.useState({
    ...arrayToObject(fields),
  });

  const handleChange = (tagName: string, value: string, color: string) => {
    setDescription((prevDescription) => {
     const newDescription= onChangeDescriptionFromTag(
        prevDescription,
        value ,
        tagName,
        color,
        formPromps
      );
      return newDescription;
    });

    setFormPromps((prevFormPromps) => ({
      ...prevFormPromps,
      [tagName]: value,
    }));
  };



  const handleSumbitPrompt = (e: any) => {
    e.preventDefault();
    const newDescription = removeTags(description);
    callback(newDescription);
  };

  const generetedColors = () => {
    if (fields.length) {
      let tmpColors: string[] = [];
      fields.forEach((field) => {
        const color = getRandomColor();
        tmpColors.push(color);
        setDescription((prevDescription) => {
          let str = prevDescription;
          str = replaceTagName(str, field, field, color, true);
          return str;
        });
      });
      setColors(tmpColors);
    }
  };

  React.useEffect(() => {
    setDescription(defaultDescription);
    generetedColors();
    if (defaultDescription) {
      setIsLoading(false);
    }
  }, [defaultDescription]);
  return (
    <form
      onSubmit={handleSumbitPrompt}
      style={{ width: '100%', display: 'flex' }}
    >
      <Grid container>
        <CustomGrid
          item
          my={1}
          xs={fields.length ? 7 : 12}
          py={1}
          px={3}
          sx={{
            ...scrollStyle,
          }}
        >
          {isLoading ? (
            <CustomContainer mt={6}>
              <Loading color="primary" size={20} type="circular" />
            </CustomContainer>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <Box mb={2}>
                <CustomTypography variant="h3">
                  {prompts?.title}
                </CustomTypography>
              </Box>
              <DescriptionPreview descriptionPreview={description}/>
            </Box>
          )}
        </CustomGrid>
        {fields.length && (
          <CustomGridListField item my={1} xs={5} py={1} px={2}>
            {isLoading ? null : (
              <EditTagContent
                fields={fields}
                colors={colors}
                formPromps={formPromps}
                handleChange={handleChange}
              />
            )}
          </CustomGridListField>
        )}
        <Grid
          xs={12}
          pb={2}
          px={2}
          display="flex"
          flexGrow={1}
          justifyContent="flex-end"
        >
          <CustomButton
            size="small"
            variant="contained"
            type="submit"
            color="primary"
            intlId="libraries.prompts.use"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default PromptsStepTwo;
