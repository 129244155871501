import { Link, useParams } from 'react-router-dom';
import Bot from '../../../../../Chat/Description/Bots/Bot/Bot';
import CustomBox from '../../../../../common/CustomBox/CustomBox';
import CustomIconButton from '../../../../../common/CustomIconButton/CustomIconButton';
import CustomTooltip from '../../../../../common/CustomToolTip/CustomToolTip';
import { useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Bot as BotType } from '../../../../../../gql/graphql';

interface SearchBotListProps {
  bots: BotType[];
}
const SearchBotList = ({ bots }: SearchBotListProps) => {
  const theme = useTheme();
  const { workspaceId } = useParams();
  return (
    <CustomBox>
      {bots?.map((bot: any) => (
        <CustomBox
          key={bot.id}
          sx={{
            paddingLeft: theme.spacing(1),
            position: 'relative',
            '&:hover': {
              backgroundColor: theme.palette.background.paper,
              borderRadius: theme.spacing(0.5),
              '& .bot-search-action': {
                display: 'flex',
              },
            },
          }}
        >
          <CustomBox
            className="bot-search-action"
            position={'absolute'}
            display={'none'}
            gap={0.5}
            right={2}
            top={2}
          >
            <CustomTooltip title={'Start chat'} arrow>
              <Link to={`../../../${workspaceId}/talk/?bot=${bot.id}`}>
                <CustomIconButton
                  size="small"
                  sx={{
                    padding: 0,
                    cursor: 'pointer',
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: '2px',
                  }}
                >
                  <PlayArrowIcon fontSize="small" />
                </CustomIconButton>
              </Link>
            </CustomTooltip>
            {bot?.baseBot?.id && (
              <CustomTooltip title={'Edit'} arrow>
                <Link to={`../../../${workspaceId}/bot/${bot.id}`}>
                  <CustomIconButton
                    size="small"
                    sx={{
                      padding: 0,
                      cursor: 'pointer',
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: '2px',
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </CustomIconButton>
                </Link>
              </CustomTooltip>
            )}
          </CustomBox>
          <Bot
            botSlug={bot.slug}
            botname={bot.name}
            botWorkspaceId={bot.workspaceId}
            isForDescription={true}
            avatarSize="large"
            avatar={bot.user?.profilePicture}
            botDescription={bot.description}
            isDisplayDescription={true}
            onClick={() => {}}
            isBot={false}
            botId={bot.id}
            key={bot.id}
            isOpenDocumentationOnClicked={true}
          />
        </CustomBox>
      ))}
    </CustomBox>
  );
};
export default SearchBotList;
