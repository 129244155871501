import * as React from 'react';

import 'moment-timezone';
import EditLabel from './EditLabel/EditLabel';
import { Todo } from '../../../../../gql/graphql';
import { Box, styled } from '@mui/material';
import EditDueDate from './EditDuedate/EditDueDate';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: '8px',
  width: '100%',
}));

const SubContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
}));

interface propsLabel {
  isEditable?: boolean;
  onClick?(e: any): void;
  isOverdue: boolean;
  todo?: Todo;
}

const Label = ({ isEditable, onClick, isOverdue, todo }: propsLabel) => {
  return (
    <Container>
      <SubContainer>
        <Box width={"80%"} onClick={onClick}>
          <EditLabel
            disabled={!Boolean(isEditable)}
            todoID={todo?.id as number}
            title={todo?.title as string}
          />
        </Box>

        {todo?.dueDate && (
          <Box width={'100%'} display="flex">
            <Box width={90}>
              <EditDueDate todo={todo as Todo} isOverdue={isOverdue} />
            </Box>
          </Box>
        )}
      </SubContainer>
    </Container>
  );
};

export default Label;
