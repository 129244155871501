import { FC } from 'react';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import CustomTypography from '../CustomTypography/CustomTypography';
interface IProps {
  options: {
    value: string | number;
    label: string;
  }[];
  value: string | number;
  onChange(e: any): void;

  name?: string;
}

const STSelect: FC<IProps> = ({ options, value, onChange, name }) => {
  const defaultValue = options && options.length ? options[0].value : '';
  return (
    <FormControl fullWidth>
      <NativeSelect
        defaultValue={defaultValue}
        value={value}
        inputProps={{
          name: name,
        }}
        onChange={onChange}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

export default STSelect;
