import * as React from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import useGetBots from '../../../../../Hooks/useGetBots';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomButton from '../../../../common/CustomButton/CustomButton';
import STBot from '../../../../common/STBot/STBot';
import useGetChatSessionDefaultBot from '../../../hooks/useGetChatSessionDefaultBot';
import SearchBot from '../../../SearchBot/SearchBot';

const DefaultChatLMMBot = () => {
  const { data: dataSourceSession } = useGetChatSessionDefaultBot();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const { data, error, loading } = useGetBots({
    isChatLMM: true,
  });

  const handleToggle = (e: any) => {
    setAnchorEl(e.currentTarget);
  };
  if (loading) return null;
  if (error) return null;
  if (!data?.bots) return null;

  const bots = data?.bots || [];
  const defaultBot = dataSourceSession?.getOneTodoChatSession?.defaultBot;

  // Swiftask bot, filter from the list of bots with slug swiftask
  const swiftaskBot = bots.find((bot: any) => bot.slug === 'swiftask');

  const defaultChatLMM = defaultBot || swiftaskBot;
  if (!defaultChatLMM) return null;
  const todoId = dataSourceSession?.getOneTodoChatSession?.todoChat?.todoId;

  return (
    <CustomBox
      sx={{
        display: 'flex',
      }}
    >
      <CustomBox
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',

          display: 'flex',
        }}
      >
        {todoId && (
          <SearchBot
            todoId={todoId}
            shouldCreateSession={false}
            triggerComponent={
              <CustomButton endIcon={<ExpandMore />} onClick={handleToggle}>
                <STBot
                  name={defaultChatLMM?.name || ''}
                  userProfil={defaultChatLMM?.user?.profilePicture}
                />
              </CustomButton>
            }
          />
        )}
      </CustomBox>
    </CustomBox>
  );
};

export default DefaultChatLMMBot;
