import { Box, TextField, Tooltip, styled } from '@mui/material';
import HeadTitle from './HeadTitle/HeadTitle';
import ListCaterogiePrompts from './ListCaterogiePrompts/ListCaterogiePrompts';
import { FragmentType, graphql } from '../../../../../../gql';
import { CATEGORY_PROMPTS_FRAGMENT } from '../../../../../../fragements/categoryPrompts';
import { useIntl } from 'react-intl';
import PlusIcon from './PlusIcon/PlusIcon';
import { useState } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useMutation } from '@apollo/client';
import Loading from '../../../../../common/Loading/Loading';
import { GET_CATEGORIES_PROMPTS } from '../SideBarPrompts';
interface props {
  intlId: string;
  categories?: FragmentType<typeof CATEGORY_PROMPTS_FRAGMENT>[] | null;
  hasInput?: boolean;
  hasPlusIcon?: boolean;
  loading: boolean;
  handleToogleShowInput?(): void;
}

const CustomContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
}));

const CustomDiv = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-end',
  marginLeft: '20px',
}));

const CREATE_CATEGORY_PROMPTS = graphql(`
  mutation CreateCategoryPrompt($title: String!) {
    createCategoryPrompt(title: $title) {
      ...CategoryPromptsFragment
    }
  }
`);

const CategoriePrompts = ({
  intlId,
  categories,
  hasInput,
  hasPlusIcon,
  loading,
  handleToogleShowInput,
}: props) => {
  const intl = useIntl();
  const [categoryName, setCategoryName] = useState('');
  const getIntl = (id: string) => {
    return intl.formatMessage({
      id,
    });
  };

  const [addNewCategory] = useMutation(CREATE_CATEGORY_PROMPTS, {
    update(cache, { data }) {
      const existingCategory: any = cache.readQuery({
        query: GET_CATEGORIES_PROMPTS,
      });
      cache.writeQuery({
        query: GET_CATEGORIES_PROMPTS,
        data: {
          getCategoriesPrompts: {
            categoryByWorkspace: [
              data?.createCategoryPrompt,
              ...existingCategory?.getCategoriesPrompts.categoryByWorkspace,
            ],
            categoryGeneral: [
              ...existingCategory?.getCategoriesPrompts.categoryGeneral,
            ],
          },
        },
      });
    },
  });

  const handleAddCategory = () => {
    if (categoryName.length) {
      addNewCategory({
        variables: {
          title: categoryName,
        },
      });
    }
    setCategoryName('');
    if (handleToogleShowInput) {
      handleToogleShowInput();
    }
  };

  return (
    <>
      {loading ? (
        <Loading size={20} type="circular" />
      ) : (
        <>
          <CustomContainer my={1}>
            {hasPlusIcon && (
              <Tooltip
                title={getIntl(`libraries.prompts.category.header`)}
                arrow
              >
                <CustomDiv>
                  <PlusIcon callBack={handleToogleShowInput} />
                </CustomDiv>
              </Tooltip>
            )}
          </CustomContainer>
          {hasInput && (
            <ClickAwayListener onClickAway={handleAddCategory}>
              <TextField
                size="small"
                autoFocus
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                placeholder={getIntl('libraries.prompts.field.placeholder')}
              />
            </ClickAwayListener>
          )}

          <Box>
            <ListCaterogiePrompts categories={categories} />
          </Box>
        </>
      )}
    </>
  );
};

export default CategoriePrompts;
