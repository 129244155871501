import CardPrompts from './CardPrompts/CardPrompts';
import { graphql } from '../../../../../../gql';
import { useContext } from 'react';
import { StepContext } from '../../../../Prompts';
import orderBy from 'lodash/orderBy';
import useGetCategoryPrompts from '../../../../../../Hooks/useGetCategoryPrompts';
import AlertText from '../../../../../common/AlertText/AlertText';
import CustomBox from '../../../../../common/CustomBox/CustomBox';

interface props {
  handleClick(id: number): void;
  idCategorie: number;
  isForCustomPrompt?: boolean;
}

export const GET_PROMPTS = graphql(`
  query FindManyPrompts($where: PromptsWhereInput) {
    findManyPrompts(where: $where) {
      id
      description
      title
      fieldsPrompts
      createdAt
    }
  }
`);

const ListPrompts = ({
  handleClick,
  idCategorie,
  isForCustomPrompt,
}: props) => {
  const { idPrompts } = useContext(StepContext);
  const { data, loading, onDeletePrompt } = useGetCategoryPrompts(idCategorie);
  let prompts = data?.getPromptsByCategory || [];
  // Order by created at
  prompts = orderBy(prompts, ['createdAt'], ['desc']);
  return (
    <>
      <CustomBox
        sx={{
          display: 'grid',
          alignItems: 'stretch',
          gridTemplateColumns: '50% 50%',
          gap: '8px',
        }}
      >
        {prompts?.map((el: any) => (
          <CardPrompts
            isForCustomPrompt={isForCustomPrompt}
            handleClick={handleClick}
            id={el.id}
            onDeletePrompt={onDeletePrompt}
            isSelected={el.id === idPrompts}
            description={el.description}
            title={el.title}
          />
        ))}
      </CustomBox>

      {prompts.length === 0 && (
        <AlertText
          severity="info"
          message={'No custum prompts yet'}
          id="prompt.category.empty"
        />
      )}
    </>
  );
};

export default ListPrompts;
