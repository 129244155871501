import * as React from 'react';
import { useQuery } from '@apollo/client';
import ErrorMessageIndication from '../common/ErrorMessageIndication/ErrorMessageIndication';
import { graphql } from '../../gql';
import { logout } from '../../utils/logout';
import { getUrlParam } from '../../utils/url';
import { useLocation } from 'react-router-dom';

const GET_CURRENT_USER_WORKSPACE = graphql(/* GraphQL */ `
  query GetCurrentMeWorkspace {
    me {
      id
      isDisplayPersonalInfoPage
      confirmed
      workspaceMembers {
        id
        role
        workspace {
          id
          name
        }
      }
    }
  }
`);

const MainIndexPage = () => {
  const botId = getUrlParam('bot');
  const location = useLocation();
  const { data, loading, error } = useQuery(GET_CURRENT_USER_WORKSPACE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // If no workspace, logout
      if (!data?.me?.workspaceMembers.length) {
        logout(location.search);
        return;
      }
      const currentWorkspace = data?.me.workspaceMembers[0].workspace;
      if (currentWorkspace) {
        if (data?.me.isDisplayPersonalInfoPage && data?.me.confirmed) {
          window.location.href = `/personal-info`;
        } else if (botId) {
          window.location.href = `/${currentWorkspace.id}/talk${location.search}`;
        } else {
          window.location.href = `/${currentWorkspace.id}/talk`;
        }
      }
    },
    onError: (error) => {
      logout(location.search);
    },
  });

  if (loading) return null;

  if (error) return null;

  if (!data?.me.workspaceMembers.length)
    return <ErrorMessageIndication message="Error on load workspace" />;

  return <></>;
};

export default MainIndexPage;
