import { useContext } from 'react';
import { SubscriptionContext } from './SubscriptionContext';
import { SUBSCRIPTION_LEVEL } from '../../../constants/subscription';

const useGetSubscriptionContext = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error(
      'useGetSubscriptionContext must be used within a SubscriptionProvider'
    );
  }
  const isFreePlan =
    context.currentUserSubscription?.code === SUBSCRIPTION_LEVEL.FREE;
  const isFreeOrBasicPlan =
    context.currentUserSubscription?.code === SUBSCRIPTION_LEVEL.FREE ||
    context.currentUserSubscription?.code === SUBSCRIPTION_LEVEL.BASIC;

  return {
    planCode: context.currentUserSubscription?.code,
    planId: context.currentUserSubscription?.id,
    isFreePlan,
    isFreeOrBasicPlan,
    currentUserSubscription: context.currentUserSubscription,
  };
};

export default useGetSubscriptionContext;
