import React, { useMemo } from 'react';
import { BoxProps, useTheme } from '@mui/material';
import { AutoAwesome } from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'react-intl';
import CheckIcon from '@mui/icons-material/Check';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import CustomButton from '../../../../common/CustomButton/CustomButton';
import useGetSubscriptionUrl from '../../../../../Hooks/useGetSubsciptionUrl';
import { SubscriptionPlan } from '../../../../../gql/graphql';

interface PricingPlanItemProps extends BoxProps {
  plan: SubscriptionPlan;
  currentPlanCode: string;
}

const PriceDisplay = ({ plan }: { plan: SubscriptionPlan }) => {
  return (
    <CustomBox
      display="flex"
      mt={'auto'}
      flexDirection="column"
      height="100%"
      width="100%"
    >
      {plan.priceToCompare && (
        <CustomTypography
          variant="h6"
          sx={{
            textDecoration: 'line-through',
            fontWeight: 'bold',
            paddingLeft: 1,
            fontSize: '13px',
          }}
        >
          {plan.priceToCompare}€
        </CustomTypography>
      )}
      <CustomTypography
        variant="h6"
        mt="auto"
        sx={{ fontWeight: 'bold', paddingLeft: 1 }}
      >
        <FormattedMessage id="price.per.month" />
        {plan.isYearly && (
          <>
            <br />
            ( <FormattedMessage id="price.per.year.indication" />)
          </>
        )}
      </CustomTypography>
    </CustomBox>
  );
};

const PlanDetails = ({ plan }: { plan: SubscriptionPlan }) => {
  const theme = useTheme();

  return (
    <CustomBox>
      <CustomTypography my={theme.spacing(1)} variant="h4">
        {plan.subscriptionPlanDetails?.title}
      </CustomTypography>
      {plan.subscriptionPlanDetails?.items?.map((item: any, index: number) => (
        <CustomBox key={index} display="flex" gap={1}>
          <CheckIcon fontSize="small" />
          <CustomTypography sx={{ fontSize: '11px' }}>{item}</CustomTypography>
        </CustomBox>
      ))}
    </CustomBox>
  );
};

const PricingPlanItem = ({
  plan,
  currentPlanCode,
  ...rest
}: PricingPlanItemProps) => {
  const theme = useTheme();
  const { startUpgrade, loadingUpdateUrl } = useGetSubscriptionUrl();
  const intl = useIntl();

  const isPro = useMemo(() => plan.code === 'PRO', [plan]);
  const isFreePlan = useMemo(() => plan.code === 'FREE', [plan]);
  const isCurrentPlan = useMemo(
    () => plan.code === currentPlanCode,
    [plan, currentPlanCode]
  );

  const handleStartUpgrade = (newPlanCode: string) => {
    startUpgrade(newPlanCode);
  };

  return (
    <CustomBox {...rest}>
      <CustomBox display="flex" gap={1}>
        {!isFreePlan && (
          <AutoAwesome
            fontSize="small"
            color={isPro ? 'secondary' : 'primary'}
          />
        )}
        <CustomTypography fontWeight="bold" variant="h3">
          {plan.name}
        </CustomTypography>
      </CustomBox>
      <CustomBox
        component="div"
        my={theme.spacing(1)}
        sx={{
          fontWeight: 'bold',
          display: 'flex',
          width: '100%',
          minheight: 40,
          gap: 1,
        }}
      >
        <CustomTypography fontWeight="bold" mt={theme.spacing(1.2)}>
          €
        </CustomTypography>
        <CustomTypography variant="h6" sx={{ fontSize: 32, fontWeight: 900 }}>
          {plan.price}
        </CustomTypography>
        <PriceDisplay plan={plan} />
      </CustomBox>
      {plan.isEnterprisePlan && (
        <CustomTypography variant="h6">
          <FormattedMessage
            id="entreprise.max.user"
            values={{ maxUser: plan.maxUser }}
          />
        </CustomTypography>
      )}
      <CustomButton
        fullWidth
        isLoading={loadingUpdateUrl}
        onClick={() => handleStartUpgrade(plan.code)}
        disabled={isCurrentPlan}
        size="small"
        label={
          isCurrentPlan
            ? intl.formatMessage({ id: 'current.sub.plan' })
            : plan.landingPageCTA
        }
        sx={{ mt: theme.spacing(1), height: '32px', borderRadius: '4px' }}
        variant="contained"
        color={isPro ? 'secondary' : 'primary'}
        startIcon={!isFreePlan ? <AutoAwesome /> : null}
      />
      {plan.isYearly && (
        <CustomTypography
          variant="caption"
          textAlign="center"
          display="block"
          color="text.secondary"
        >
          <FormattedMessage id="plan.annual.billed" />
        </CustomTypography>
      )}
      <PlanDetails plan={plan} />
    </CustomBox>
  );
};

export default PricingPlanItem;
