import * as React from 'react';
import Loading from '../../../common/Loading/Loading';
import AlertText from '../../../common/AlertText/AlertText';
import useGetBots from '../../../../Hooks/useGetBots';
import CustomBox from '../../../common/CustomBox/CustomBox';
import BotList from './BotsList/BotsList';
import useCreateChatSession from '../../hooks/useCreateChatSession';
import { Mixpanel } from '../../../../mixpanel/mixpanel';
import useNavigateToChatSession from '../../hooks/useNavigateToChatSession';
import useGetBotsByTag from '../../../../Hooks/useGetBotsByTag';
import { useIntl } from 'react-intl';
import SearchBot from '../../SearchBot/SearchBot';

interface IProps {
  todoId: number;
}

const Bots: React.FC<IProps> = ({ todoId }) => {
  const { data, error, loading } = useGetBots({});
  const intl = useIntl();

  const { bots: newBots, loading: loadingNewsBot } = useGetBotsByTag({
    tag: 'news',
    limit: 1000,
  });
  const goToChatSession = useNavigateToChatSession();
  const { createChatSession } = useCreateChatSession({
    todoId: todoId as number,
  });
  if (loading) return <Loading />;

  if (error) return <AlertText severity="error" message={error.message} />;
  if (!data?.bots) return null;
  const onBotClick = (bot: any) => {
    Mixpanel.track('Start Conversation with Bot', {
      botUserId: bot.user.id,
      botSlug: bot.slug,
    });
    // Create a new chat session
    createChatSession({
      variables: {
        data: {
          todoId: todoId as number,
          defaultBotId: bot.id,
          title: bot.name,
        },
      },
    }).then((res) => {
      if (res?.data?.createTodoChatSession)
        goToChatSession({
          todoId: todoId as number,
          chatSessionId: res?.data?.createTodoChatSession?.id,
          dataSources: res?.data?.createTodoChatSession?.dataSources,
        });
    });
  };

  return (
    <CustomBox
      sx={{
        width: '100%',
      }}
    >
      {newBots.length > 0 ? (
        <CustomBox>
          <BotList
            bots={newBots}
            onBotClick={onBotClick}
            todoId={todoId}
            categoryTitle={intl.formatMessage({
              id: 'bot.tag.new',
            })}
            isNew={true}
          />
        </CustomBox>
      ) : null}
    </CustomBox>
  );
};
export default React.memo(Bots);
