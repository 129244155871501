import { FC } from 'react';
import CustomBox from '../../../common/CustomBox/CustomBox';
import ChatOnboarding from './ChatOnboarding';
import useGetChatSessionDefaultBot from '../../hooks/useGetChatSessionDefaultBot';
import BotDetails from '../../../common/BotUI/BotDetails';
import { BotDetailsType } from '../../../../types/BotDetails';
import useGetMe from '../../../../Hooks/useGetMe';
import ChatDataSourceAdd from '../ChatDatasourceAdd/ChatDatasourceAdd';

interface IProps {
  todoId: number;
  sessionId: number;
}

const ChatOnBoardingContainer: FC<IProps> = ({ todoId, sessionId }) => {
  const { data } = useGetChatSessionDefaultBot();
  const { email } = useGetMe();
  const dataSources = data?.getOneTodoChatSession?.allDataSources || [];
  const defaultBot = data?.getOneTodoChatSession?.defaultBot;
  const hasDataSources = Boolean(dataSources.length > 0);
  const botUser = defaultBot?.user;
  // If default bot not swiftask
  const isSwiftask = defaultBot?.slug === 'swiftask' || !defaultBot;

  if (isSwiftask) return <ChatOnboarding todoId={todoId} />;
  if (!botUser) return null;

  return (
    <CustomBox>
      <BotDetails
        bot={defaultBot as BotDetailsType}
        displayCreditInfo={true}
        currentUserEmail={email}
        isDisplayBotAttributes={true}
      />

      {defaultBot.isDisplayFileFormOnFirstSession && !hasDataSources && (
        <CustomBox mt={(theme) => theme.spacing(2)}>
          <ChatDataSourceAdd defaultBot={defaultBot} sessionId={sessionId} />
        </CustomBox>
      )}
    </CustomBox>
  );
};

export default ChatOnBoardingContainer;
