import { useContext } from 'react';
import { CATEGORY_PROMPTS_FRAGMENT } from '../../../../../../../fragements/categoryPrompts';
import { FragmentType, useFragment } from '../../../../../../../gql';
import STList from '../../../../../../common/STList/STList';
import { StepContext } from '../../../../../Prompts';
import { find, filter } from 'lodash';

interface props {
  categories?: FragmentType<typeof CATEGORY_PROMPTS_FRAGMENT>[] | null;
}

const ListCaterogiePrompts = ({ categories }: props) => {
  const categoriesDataFragment = useFragment(
    CATEGORY_PROMPTS_FRAGMENT,
    categories
  );

  const {
    updateStep,
    handleNextStep,
    lastStep,
    handlePreviousStep,
    idCategorie,
  } = useContext(StepContext);

  const handleChange = (
    categoryName: string,
    idCategorie: number,
    isCustomCategory: boolean | null | undefined
  ) => {
    updateStep({
      activeStep: 0,
      lastStep,
      idPrompts: 0,
      categoryName,
      idCategorie,
      isCustomCategory,
      updateStep,
      handleNextStep,
      handlePreviousStep,
    });
  };
  const filteredItems = filter(
    categoriesDataFragment,
    (item) => !item.isCustomWorkspace
  );
  const customItem = find(categoriesDataFragment, { isCustomWorkspace: true });
  // Put custom item at the beginning of the list
  const itemsWithCustom = customItem
    ? [customItem, ...filteredItems]
    : filteredItems;

  const items =
    itemsWithCustom?.map((element, index) => ({
      id: element.id,
      primaryText: element.title,
      onItemClick: (item: { primaryText: string; id: number }) =>
        handleChange(item.primaryText, item.id, element.isCustomWorkspace),
    })) || [];

  return (
    <>
      <STList selectedItemId={idCategorie} items={items} />
    </>
  );
};

export default ListCaterogiePrompts;
