import { SvgIconComponent } from '@mui/icons-material';
import { isSameYear, isToday, isYesterday, startOfMonth } from 'date-fns';
import { groupBy } from 'lodash';
import { useIntl } from 'react-intl';
type IconColor =
  | 'inherit'
  | 'action'
  | 'disabled'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

type IconSize = 'inherit' | 'large' | 'medium' | 'small';
interface Action {
  disabled?: boolean;
  iconColor?: IconColor;
  iconSize?: IconSize;
  icon: SvgIconComponent;
  tooltipIntlId: string;
  onClick: (item: Item) => void;
}
interface Item {
  id: number;
  primaryText: string;
  userProfil?:string;
  secondaryText?: string;
  createdAt?: any;
  avatarImg?: string; // optional avatarImg property
  unreadNotificationsCount?: number;
  ListItemLeft?: React.ReactNode; // optional LeftItem property
  actions?: Action[]; // optional actions property
  onItemTextClick?: (item: Item) => void; // optional onItemTextClick property
  onItemClick?: (item: Item) => void; // optional onItemClicked property
  href?: string; // optional href property
}
type groupByType = 'createdAt' | 'updatedAt';
const useGroupSTList = (items: Item[], groupByProp?: groupByType) => {
  const intl = useIntl();
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  const firstDayOfMonth = startOfMonth(today);
  const currentDate = today.getDate();
  const previousDays = currentDate - firstDayOfMonth.getDate();

  let groupedByDate;

  switch (groupByProp) {
    case 'createdAt':
      groupedByDate = groupBy(items, (item) => {
        const itemDate = new Date(item.createdAt);
        switch (true) {
          case isToday(itemDate):
            return intl.formatMessage({ id: 'group.name.today' });
          case isYesterday(itemDate):
            return intl.formatMessage({ id: 'group.name.yesterday' });
          case itemDate > firstDayOfMonth && itemDate <= yesterday:
            return `${intl.formatMessage(
              { id: 'group.name.previous.day' },
              {
                previousDays,
              }
            )}`;
          default:
            const yearName = isSameYear(itemDate, today)
              ? ''
              : itemDate.getFullYear().toString();
            const monthName = itemDate.toLocaleString('default', {
              month: 'long',
            });
            return `${monthName} ${yearName}`;
        }
      });
      break;

    default:
      groupedByDate = null;
      break;
  }

  if (groupedByDate === null) {
    return null;
  }

  const groupeditems = Object.entries(groupedByDate).map(([name, items]) => ({
    name,
    items,
  }));

  return groupeditems;
};

export default useGroupSTList;
