import CustomBox from '../../../common/CustomBox/CustomBox';
import { Add } from '@mui/icons-material';

import { FormattedMessage } from 'react-intl';
import CustomButton from '../../../common/CustomButton/CustomButton';
interface props {
  handleOpen(): void;
  isCompact: boolean;
}
const AddProjectButton = ({ handleOpen, isCompact }: props) => {
  return (
    <CustomBox width={'100%'} id="menu-add-project">
      <CustomButton
        startIcon={<Add />}
        onClick={handleOpen}
        variant="contained"
        sx={{
          height: (theme) => theme.spacing(4.5),
        }}
      >
        <FormattedMessage id="project.list.add" />
      </CustomButton>
    </CustomBox>
  );
};
export default AddProjectButton;
