import * as React from 'react';
import { Avatar, Box } from '@mui/material';
export interface propsProfil {
  userProfil: string | null | undefined;
  alt?: string;
  sx?: any;
}
const UserAvatar = ({ userProfil, alt, sx }: propsProfil) => {
  const defaultProfil =
    'https://res.cloudinary.com/dsht8f3ff/image/upload/v1688023162/image_suy0ge.png';
  return (
    <Avatar
      variant="rounded"
      src={userProfil || defaultProfil}
      alt={alt}
      sx={
        sx
          ? sx
          : {
              width: '24px',
              height: '24px',
              borderRadius: '4px',
            }
      }
    />
  );
};

export default UserAvatar;
