import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { graphql } from '../../../gql';
import { Bot } from '../../../gql/graphql';

const GET_DATASOURCE_SESSION = graphql(`
  query GetOneTodoChatSessionConfig($sessionId: Float!) {
    getOneTodoChatSession(sessionId: $sessionId) {
      id
      allDataSources {
        id
        url
      }
      defaultBot {
        id
        name
        isDisplayFileFormOnFirstSession
        fileFormOnFirstSessionPlaceholder
        isStoppable
        slug
        enableFollowupQuestion
        acceptFileInput
        acceptTextInput
        messageInputPlaceHolder
        stripePaymentLink
        currentUserCredit {
          totalRemainingCredit
        }
        isEnableStream
        isMultiBot
        supportedContentTypes {
          id
          extension
          type
        }
        botUIs {
          slug
          name
          id
          config
        }
        formFields {
          formName
          descriptionFR
          description
          label
          labelFR
          placeholder
          placeholderFR
          rangeMax
          rangeMin
          type
          order
          defaultValue
          supportedFiletypes
          options {
            label
            description
            value
          }
        }
        isPromptRequired
        uiLayout
      }
    }
  }
`);

const useGetChatSessionState = () => {
  const params = useParams();
  const { data, loading } = useQuery(GET_DATASOURCE_SESSION, {
    variables: {
      sessionId: +(params?.sessionId || 0),
    },
    fetchPolicy: 'cache-first',
  });
  const defaultBot = data?.getOneTodoChatSession?.defaultBot || ({} as Bot);
  const dataSources = data?.getOneTodoChatSession?.allDataSources || [];
  const hasDataSources = dataSources && dataSources.length > 0;

  return {
    data,
    loading,
    defaultBot,
    hasDataSources,
    dataSources,
  };
};

export default useGetChatSessionState;
