import Grid from '@mui/material/Grid';
import CustomBox from '../../../common/CustomBox/CustomBox';
import AiStore from './AiStore/AiStore';
import Hidden from '@mui/material/Hidden';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
interface props {
  todoId: number;
}
const ChatOnboarding = ({ todoId }: props) => {
  return (
    <CustomBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomBox>
        <Grid container justifyContent="center">
          <Hidden smDown>
            <Grid item xs={12} sm={8} md={4}>
              <CustomTypography
                variant="h6"
                align="center"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                <FormattedMessage id="chat.onboarding.welcome.text" />
              </CustomTypography>
            </Grid>
          </Hidden>
        </Grid>
        <CustomBox>
          <AiStore todoId={todoId} />
        </CustomBox>
      </CustomBox>
    </CustomBox>
  );
};

export default ChatOnboarding;
