import CustomTooltip from '../../common/CustomToolTip/CustomToolTip';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconButton, styled } from '@mui/material';
import CustomButton from '../../common/CustomButton/CustomButton';
const StyledButton = styled(CustomButton)(({ theme }) => ({
  height: theme.spacing(3.5),
  borderRadius: theme.spacing(0.7),
  marginLeft: theme.spacing(0.5),
  marginTop: theme.spacing(1.7),
  borderColor: theme.palette.grey[200],
  color: theme.palette.grey[200],
  '&:hover': {
    color: theme.palette.text.primary,
    borderColor: theme.palette.grey[200],
  },
}));
interface props {
  handleClick: () => void;
}

const ButtonPrompts = ({ handleClick }: props) => {
  const intl = useIntl();
  return (
    <StyledButton
      color="primary"
      variant="outlined"
      onClick={handleClick}
      label={intl.formatMessage({
        id: 'button.prompts',
        defaultMessage: 'Browse Prompts',
      })}
    />
  );
};

export default ButtonPrompts;
