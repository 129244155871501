import * as React from 'react';
import { Tab, TabProps, styled } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import { getUrlParam } from '../../../utils/url';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

const TabStyled = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,

  '&.Mui-selected': {
    zIndex: 999,
    color: '#fff',
    opacity: 1,
    fontWeight: 'bold',
  },
  marginTop: '-10px',
}));
const TabsStyled = styled(TabList)(({ theme }) => ({
  //backgroundColor: theme.palette.background.paper,
  padding: '8px 4px',
  borderRadius: theme.spacing(0.5),
  height: theme.spacing(5),
}));
interface IProps extends TabProps {
  tabs: {
    component: React.ReactNode;
    id: string;
    label: string;
    textLabel?: string;
    disabled?: boolean;
  }[];
  defaultTab?: string;
  onTabChange?(previousTab: string, currentTab: string): void;
  orientation?: 'horizontal' | 'vertical';
  centered?: boolean;
}
const STTab = ({
  tabs,
  defaultTab,
  onTabChange,
  orientation,
  centered,
  ...rest
}: IProps) => {
  const location = useLocation();
  const initialTab = React.useMemo(
    () => getUrlParam('tab') || defaultTab || tabs[0]?.id,
    [tabs[0]?.id, defaultTab, location.search]
  );
  const intl = useIntl();
  const [value, setValue] = React.useState(initialTab);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (onTabChange) {
      onTabChange(value, newValue);
    }
    setValue(newValue);
  };
  // Use effect
  React.useEffect(() => {
    const tabFromUrl = getUrlParam('tab');
    if (tabFromUrl && tabFromUrl !== value) {
      setValue(tabFromUrl);
    }
  }, [location.search]);
  return (
    <TabContext value={value}>
      <TabsStyled
        onChange={handleChange}
        orientation={orientation || 'horizontal'}
        TabIndicatorProps={{
          sx: {
            height: '100%',
            borderRadius: 2,
          },
        }}
        centered={centered}
      >
        {tabs.map((tab: any) => (
          <TabStyled
            label={tab?.textLabel ?? intl.formatMessage({ id: tab.label })}
            value={tab.id}
            disabled={tab.disabled}
          />
        ))}
      </TabsStyled>
      {tabs.map((tab: any) => (
        <TabPanel sx={{ p: 0, mt: 1 }} value={tab.id}>
          {tab.component}
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default STTab;
