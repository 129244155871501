import { FC, useContext } from 'react';
import CustomBox from '../../../common/CustomBox/CustomBox';
import { ChatContext } from '../../Chat';
import STTab from '../../../common/STTab/STTab';
import TodoInfo from './TodoInfo/TodoInfo';
import Bots from '../Bots/Bots';
import ToDoDescription from '../ToDoDescription/ToDoDescription';
import React from 'react';
import useGetChatSessionDataSource from '../../hooks/useGetChatSessionDataSource';
import ChatSessionDataSources from './ChatSessionDataSources/ChatSessionDataSources';
import {
  ProviderSkeleton,
  SkeletonContent,
} from '../../../common/Skeleton/Skeleton';
import BotExtraConfig from './BotExtraConfig/BotExtraConfig';

interface IProps {
  isForChat: boolean;
  todoId: number;
}

const TodoDetailsContainer: FC<IProps> = ({ isForChat, todoId }) => {
  const chatContext = useContext(ChatContext);
  const { data, loading, pdfUrl, shouldDisplayDataSource } =
    useGetChatSessionDataSource({
      skip: !isForChat,
    });

  const descriptionLabel = chatContext.isInifiniteChat
    ? 'todo.tab.notes'
    : 'todo.tab.description';
  const tabsData = [];

  if ((data?.getOneTodoChatSession?.defaultBot?.formFields || []).length > 0) {
    tabsData.push({
      component: (
        <CustomBox
          sx={{
            height: 'calc(100vh - 110px)',
          }}
        >
          <BotExtraConfig
            formFields={
              data?.getOneTodoChatSession?.defaultBot?.formFields as any
            }
          />
        </CustomBox>
      ),
      label: 'todo.tab.botUI',
      id: 'todo.tab.botUI',
    });
  }

  if (chatContext.isInifiniteChat) {
    if (shouldDisplayDataSource) {
      <CustomBox
        sx={{
          height: 'calc(100vh - 50px)',
          width: '100%',
        }}
      >
        <ChatSessionDataSources
          dataSources={data}
          todoId={todoId}
          pdfUrl={pdfUrl}
        />
      </CustomBox>;
    } else if (tabsData.length === 0) {
      return (
        <CustomBox>
          <Bots todoId={todoId} />
        </CustomBox>
      );
    }
  }

  // If shouldDisplayDataSource is true, then we display the PDFViewer
  if (shouldDisplayDataSource && isForChat) {
    tabsData.push({
      component: (
        <CustomBox
          sx={{
            height: 'calc(100vh - 50px)',
            width: '100%',
          }}
        >
          <ChatSessionDataSources
            dataSources={data}
            todoId={todoId}
            pdfUrl={pdfUrl}
          />
        </CustomBox>
      ),
      label: 'todo.tab.documentPreview',
      id: 'todo.tab.documentPreview',
    });
  }

  if (!chatContext.isInifiniteChat) {
    tabsData.push({
      component: (
        <CustomBox>
          <TodoInfo todoId={todoId} isForChat={isForChat} />
        </CustomBox>
      ),

      label: 'todo.tab.info',
      id: 'todo.tab.info',
    });
  }

  if (isForChat || chatContext.isInifiniteChat) {
    tabsData.push({
      component: (
        <CustomBox>
          <Bots todoId={todoId} />
        </CustomBox>
      ),
      label: 'todo.tab.bots',
      id: 'todo.tab.bots',
    });
  }

  if (!chatContext.isInifiniteChat) {
    tabsData.push({
      component: (
        <CustomBox
          sx={{
            height: 'calc(100vh - 110px)',
          }}
        >
          <ToDoDescription todoID={todoId} />
        </CustomBox>
      ),
      label: descriptionLabel,
      id: descriptionLabel,
    });
  }

  if (loading) {
    return (
      <ProviderSkeleton>
        <CustomBox mt={2} mb={2}>
          <SkeletonContent height={20} />
          <SkeletonContent height={20} />
          <SkeletonContent height={20} />
          <SkeletonContent height={20} />
        </CustomBox>
      </ProviderSkeleton>
    );
  }

  return <STTab tabs={tabsData} />;
};

export default React.memo(TodoDetailsContainer);
