import React from 'react';
import { Hidden } from '@mui/material';
import { Resizable } from 're-resizable';
import useChatLayoutFormField from '../hooks/useChatLayoutFormField';
import CustomBox from '../../common/CustomBox/CustomBox';
import useChatLayoutSize from '../hooks/useChatLayoutSize';
import { RightContainer, LeftContainer } from './ChatContainerLayout.style';
import ChatBodyContainer from '../BodyChat/ChatBodyContainer/ChatBodyContainer';
import TodoDetailsContainer from '../Description/TodoDetailsContainer/TodoDetailsContainer';
import BotExtraConfig from '../Description/TodoDetailsContainer/BotExtraConfig/BotExtraConfig';
import { Form, useParams } from 'react-router-dom';
import CustomTypography from '../../common/CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import BodyLoading from '../BodyLoading/BodyLoading';

interface IProps {
  todoId: number;
}

const ChatContainerLayout: React.FC<IProps> = ({ todoId }) => {
  const { chatWidth, onResizeStop, isSm } = useChatLayoutSize();
  const { sessionId } = useParams();
  const { defaultBot, loading } = useChatLayoutFormField();

  if (loading) {
    return (
      <CustomBox
        sx={{
          width: {
            sm: chatWidth,
            xs: '100%',
          },
          padding: (theme) => theme.spacing(1),
        }}
      >
        <BodyLoading />
      </CustomBox>
    );
  }

  if (defaultBot.uiLayout === 'FORM_AND_CHAT') {
    return (
      <CustomBox
        sx={{
          display: 'flex',
          width: '95%',
          gap: (theme) => theme.spacing(1),
          height: '95vh',
          flexDirection: {
            xs: 'column', // For small screens (mobile)
            sm: 'row', // For medium screens and up
          },
        }}
      >
        <CustomBox
          sx={{
            flex: 1,
            padding: (theme) => theme.spacing(1),
            boxSizing: 'border-box',
          }}
        >
          <CustomTypography component="h2" sx={{ margin: '4px 0 4px 0' }}>
            <FormattedMessage id="todo.tab.botUI" />
          </CustomTypography>
          <BotExtraConfig
            formFields={defaultBot.formFields as any}
            option={{
              allowsendNewMessage: true,
              botId: defaultBot.id,
              sessionId: +(sessionId || 0),
              todoId: todoId,
            }}
          />
        </CustomBox>
        <CustomBox
          sx={{
            flex: 1,
            padding: (theme) => theme.spacing(1),
            position: 'relative',
            boxSizing: 'border-box',
          }}
        >
          <ChatBodyContainer todoId={todoId} />
        </CustomBox>
      </CustomBox>
    );
  }

  return (
    <>
      <Resizable
        onResize={onResizeStop}
        size={{
          width: chatWidth,
          height: '100%',
        }}
        enable={{
          top: false,
          right: !isSm,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
      >
        <LeftContainer>
          <ChatBodyContainer todoId={todoId} />
        </LeftContainer>
      </Resizable>

      <Hidden mdDown>
        <RightContainer px={2}>
          <TodoDetailsContainer todoId={todoId} isForChat={true} />
        </RightContainer>
      </Hidden>
    </>
  );
};

export default React.memo(ChatContainerLayout);
