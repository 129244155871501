import SearchIcon from '@mui/icons-material/Search';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import CustomBox from '../../../common/CustomBox/CustomBox';
import useSearch from './useSearch';
import CustomDialog from '../../../common/CustomDialog/CustomDialog';
import CustomSearchInput from '../../../common/CustomSearchInput/CustomSearchInput';
import { useIntl } from 'react-intl';
import SearchLoading from './SearchLoading/SearchLoading';
import GlobalSearchContent from './GlobalSearchContent/GlobalSearchContent';
import { Bot, ChatDataSource, Project, Todo } from '../../../../gql/graphql';
import CustomIconButton from '../../../common/CustomIconButton/CustomIconButton';
import useTheme from '@mui/system/useTheme';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import Close from '@mui/icons-material/Close';
import SearchBox from './SearchBox/SearchBox';
const Search = styled('div')(({ theme }: any) => {
  return {
    position: 'relative',
    fontSize: '13px',
    display: 'flex',
    paddingRight:theme.spacing(1),
    cursor: 'pointer',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('width'),
    backgroundColor: alpha(theme.palette.common.white, 0.25),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.3),
    },
    width: '100%',
    height: '30px',
  };
});

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0.5, 0.8),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5, 0.5),
  },
}));

const SearchBody = ({ isCompact }: { isCompact: boolean }) => {
  const intl = useIntl();
  const theme = useTheme();
  const {
    data,
    loading,
    searchValue,
    selectedSection,
    handleSelectSection,
    onChangeInputSearch,
    isDisplaySearchDialog,
    handleOpenSearchDialog,
    handleCloseSearchDialog,
  } = useSearch();
  const todos = data?.getGlobalSearch.todos;
  const bots = data?.getGlobalSearch.bots;
  const dataSource = data?.getGlobalSearch.dataSources;
  const projects = data?.getGlobalSearch.projects;
  return (
    <CustomBox>
      <Search onClick={handleOpenSearchDialog}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <CustomBox sx={{ display: isCompact ? 'none' : 'block' }}>
          <SearchBox />
        </CustomBox>
        <CustomIconButton
          size="small"
          sx={{
            padding: 0.2,
            cursor: 'pointer',
            height: 21,
            ml:'auto',
            fontSize:'14px',
            display: isCompact ? 'none' : ['none', 'flex'],
            fontWeight: 'bold',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '4px',
          }}
        >
          <KeyboardCommandKeyIcon fontSize="small" sx={{width:'15px'}}/>
          +K
        </CustomIconButton>
      </Search>
      <CustomDialog
        sx={{ zIndex: 999 }}
        open={isDisplaySearchDialog}
        handleClose={handleCloseSearchDialog}
        scroll="paper"
        contentChildren={
          <CustomBox>
            <CustomBox px={1} display={'flex'} gap={1} width={'100%'}>
              <CustomBox sx={{ width: '95%' }}>
                <CustomSearchInput
                  loading={loading}
                  autoFocus={true}
                  searchValue={searchValue}
                  searchInputPlaceholder={intl.formatMessage({
                    id: 'global.search.placeholder',
                  })}
                  onChangeInputSearch={onChangeInputSearch}
                />
              </CustomBox>
              <CustomIconButton
                onClick={handleCloseSearchDialog}
                size="small"
                sx={{
                  padding: 0.2,
                  cursor: 'pointer',
                  width: '40px',
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: '4px',
                }}
              >
                <Close fontSize="small" />
              </CustomIconButton>
            </CustomBox>
            <CustomBox height={'70vh'} overflow="auto" px={1}>
              {loading ? (
                <SearchLoading
                  todoLoadLength={4}
                  botsLoadLength={6}
                  dataSourceLoadLength={5}
                />
              ) : (
                <GlobalSearchContent
                  todos={todos as Todo[]}
                  selectedSection={selectedSection}
                  handleSelectSection={handleSelectSection}
                  bots={bots as Bot[]}
                  dataSource={dataSource as ChatDataSource[]}
                  projects={projects as Project[]}
                />
              )}
            </CustomBox>
          </CustomBox>
        }
      />
    </CustomBox>
  );
};

export default SearchBody;
