import { Box } from '@mui/material';
import HeadPrompts from './HeadPrompts/HeadPrompts';
import ListPrompts from './ListPrompts/ListPrompts';
import { graphql } from '../../../../../gql';
import { useQuery } from '@apollo/client';
import { StepContext } from '../../../Prompts';
import { useContext } from 'react';
import Loading from '../../../../common/Loading/Loading';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import CustomPromtsStep1 from './ListPrompts/CustomPromptsStep1/CustomPromtsStep1';

interface props {
  handleSelectedPrompt(id: number): void;
}

const ContainerPrompts = ({ handleSelectedPrompt }: props) => {
  const { idCategorie, categoryName, handleNextStep, isCustomCategory } =
    useContext(StepContext);

  const handleClickNewCustomPrompt = () => {
    handleNextStep(null, 2, 0);
  };

  return (
    <div key={idCategorie}>
      <Box
        my={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <HeadPrompts categoryName={categoryName} />
      </Box>
      <Box>
        {isCustomCategory ? (
          <CustomPromtsStep1
            idCategorie={idCategorie}
            handleClickNewCustomPrompt={handleClickNewCustomPrompt}
            handleSelectedPrompt={handleSelectedPrompt}
          />
        ) : (
          <ListPrompts
            handleClick={handleSelectedPrompt}
            idCategorie={idCategorie}
            isForCustomPrompt={false}
          />
        )}
      </Box>
    </div>
  );
};

export default ContainerPrompts;
