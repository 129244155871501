import { List, ListProps } from '@mui/material';
import CustomBox from '../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import useGroupSTList from '../../../common/STList/useGroupSTList';
import SessionItem from './SessionItem/SessionItem';
interface Props extends ListProps {
  sessions: any[];
  groupBy: any;
  selectedItemId: number;
  onClickSession: (session: any) => void;
  handleDeleteSessions(): void;
  handleUpdateSessionName(newSessionName: string): void;
}
const SessionGrouped = ({
  groupBy,
  sessions,
  onClickSession,
  selectedItemId,
  handleDeleteSessions,
  handleUpdateSessionName,
  ...rest
}: Props) => {
  const groupedSessions = useGroupSTList(sessions, groupBy);
  return (
    <List {...rest}>
      {groupedSessions?.length ? (
        groupedSessions.map((data) => (
          <CustomBox key={data.name}>
            {data.items.length ? (
              <CustomTypography
                fontWeight={600}
                my={1}
                sx={{
                  color: (theme) => theme.palette.grey[200],
                  marginLeft: (theme) => theme.spacing(2),
                }}
              >
                {data.name}
              </CustomTypography>
            ) : null}
            <>
              {data?.items.map((session: any) => (
                <SessionItem
                  session={session}
                  selectedItemId={selectedItemId}
                  isDisabledToDelete={data?.items.length === 1}
                  handleDeleteSessions={handleDeleteSessions}
                  onClickSession={() => onClickSession(session)}
                  handleUpdateSessionName={handleUpdateSessionName}
                />
              ))}
            </>
          </CustomBox>
        ))
      ) : (
        <>
          {sessions?.map((session: any) => (
            <SessionItem
              session={session}
              selectedItemId={selectedItemId}
              isDisabledToDelete={sessions.length === 1}
              handleDeleteSessions={handleDeleteSessions}
              onClickSession={() => onClickSession(session)}
              handleUpdateSessionName={handleUpdateSessionName}
            />
          ))}
        </>
      )}
    </List>
  );
};
export default SessionGrouped;
