import { FormattedMessage } from 'react-intl';
import CustomDialog from '../../common/CustomDialog/CustomDialog';
import BodyPrompts from '../BodyPrompts/BodyPrompts';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ActionButton from '../BodyPrompts/ActionButton/ActionButton';
import { useContext } from 'react';
import { StepContext } from '../Prompts';

interface props {
  open: boolean;
  handleClose: () => void;
  handleValidePrompts(prompts: string): void;
}
const ModalPrompts = ({ open, handleClose, handleValidePrompts }: props) => {
  const { activeStep, handlePreviousStep } = useContext(StepContext);
  return (
    <CustomDialog
      open={open}
      leftItem={
        activeStep > 0 ? (
          <ActionButton
            isDisabled={false}
            handleClick={() => handlePreviousStep()}
            style={{
              justifyContent: 'flex-start',
              minWidth: '35px',
              margin: '0px 5px 0px -15px',
            }}
          >
            <ArrowBackIcon />
          </ActionButton>
        ) : null
      }
      title={<FormattedMessage id="libraries.prompts.modal.title" />}
      scroll="paper"
      maxWidth="md"
      sx={{
        '& .MuiDialogContent-root': {
          padding: '0px',
        },
      }}
      handleClose={handleClose}
      contentChildren={
        <BodyPrompts
          handleValidePrompts={(prompts) => {
            handleValidePrompts(prompts);
            handleClose();
          }}
        />
      }
    />
  );
};

export default ModalPrompts;
