// Mutation create chat session

import { useMutation } from '@apollo/client';
import { graphql } from '../../../gql';
import { GET_CHAT_SESSIONS } from './useGetAllChatSessions';
const CREATE_CHAT_SESSION = graphql(`
  mutation CreateChatSession($data: TodoChatSessionInput!) {
    createTodoChatSession(data: $data) {
      id
      title
      dataSources {
        id
        name
        url
        fileType
      }
    }
  }
`);

const useCreateChatSession = ({
  todoId,
  onCompleted,
}: {
  todoId?: number;

  onCompleted?: (data: any) => void;
}) => {
  const [createChatSession, { loading: loadingNewSession }] = useMutation(
    CREATE_CHAT_SESSION,

    {
      update(cache, { data }) {
        if (!data || typeof todoId === 'undefined') {
          return;
        }
        const existingChatSessions: any = cache.readQuery({
          query: GET_CHAT_SESSIONS,
          variables: {
            todoId
          },
        });

        cache.writeQuery({
          query: GET_CHAT_SESSIONS,
          variables: {
            todoId,
          },
          data: {
            getTodoChatSessions: [
              ...existingChatSessions.getTodoChatSessions,
              data.createTodoChatSession,
            ],
          },
        });
        onCompleted && onCompleted(data);
      },
    }
  );

  return {
    createChatSession,
    loadingNewSession,
  };
};

export default useCreateChatSession;
