import CustomDialog from '../../../common/CustomDialog/CustomDialog';
import ListBotByTags from './ListBotByTags/ListBotByTags';

interface IProps {
  todoId: number;
  openDialog: boolean;
  handleCloseDialog(): void;
  shouldCreateSession?: boolean;
}

const SearchBotDialog = ({
  todoId,
  openDialog,
  handleCloseDialog,
  shouldCreateSession = false,
}: IProps) => {
  return (
    <CustomDialog
      open={openDialog}
      handleClose={handleCloseDialog}
      scroll="paper"
      contentChildren={
        <ListBotByTags
          todoId={todoId}
          isDisplayBotsTag={true}
          onBotClicked={handleCloseDialog}
          shouldCreateSession={shouldCreateSession}
          sx={{
            mt: (theme) => theme.spacing(1),
            maxHeight: '60vh',
            height: '60vh',
            overflowY: 'auto',
          }}
          handleCloseSearchDialog={handleCloseDialog}
        />
      }
    />
  );
};
export default SearchBotDialog;
