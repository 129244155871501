import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomBox from '../../CustomBox/CustomBox';
import CustomTypography from '../../CustomTypography/CustomTypography';
import STChip from '../../STChip/STChip';
import {
  Theme,
  styled,
  LinearProgress,
  linearProgressClasses,
} from '@mui/material';
import CustomButton from '../../CustomButton/CustomButton';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { convertToKFormat } from '../../../../utils/ConvertToKFormat';
import CustomTooltip from '../../CustomToolTip/CustomToolTip';
import STDialogVideo from '../../STDialogVideo/STDialogVideo';
import useBotAttributesUtils from './useBotAttributesUtils';
import STDivider from '../../STDivider/STDivider';
import { llmContextToEnglishPage } from '../../../../utils/llmContextToEnglishPage';
import { formatNumber } from '../../../../utils/numberUtil';

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme }: { theme: Theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.divider,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.info.main,
    },
  })
);

interface BotAttributesProps {
  botId: number;
}

type BotAttribute = {
  id: string;
  rangeValue?: number;
  value?: number;
  tooltipLabel?: string;
  component?: React.ReactNode;
};

interface BotAttributeItemProps {
  attribute: BotAttribute;
}

const BotAttributeItem: React.FC<BotAttributeItemProps> = React.memo(
  ({ attribute }) => {
    const rangeValuePercentage = useMemo(() => {
      return attribute.rangeValue ? (attribute.rangeValue / 5) * 100 : 0;
    }, [attribute.rangeValue]);

    // If no rangeValue or value, return null
    if (!attribute.rangeValue && !attribute.value && !attribute.component) {
      return null;
    }

    return (
      <CustomTooltip title={attribute.tooltipLabel || ''} arrow>
        <div>
          <CustomBox
            display="flex"
            alignItems="center"
            gap={2}
            width="100%"
            mb={(theme: Theme) => theme.spacing(1)}
          >
            <CustomTypography
              width={['30%', '20%']}
              fontWeight="bold"
              variant="body2"
            >
              <FormattedMessage id={attribute.id} />
            </CustomTypography>

            {attribute.rangeValue && (
              <CustomBox width="50%" display="flex" gap={1} alignItems="center">
                <CustomBox width="90%" alignItems="center">
                  <BorderLinearProgress
                    variant="determinate"
                    value={rangeValuePercentage}
                  />
                </CustomBox>
              </CustomBox>
            )}
            {attribute.value && (
              <CustomTypography width="80%">
                {convertToKFormat(attribute.value)}
              </CustomTypography>
            )}
            {attribute.component && (
              <CustomBox width="80%">{attribute.component}</CustomBox>
            )}
          </CustomBox>
        </div>
      </CustomTooltip>
    );
  }
);
const BotAttributes: React.FC<BotAttributesProps> = ({ botId }) => {
  const intl = useIntl();
  const {
    openBotDemo,
    handleCloseBotDemo,
    handleOpenBotDemo,
    attributes,
    bot,
  } = useBotAttributesUtils({ botId });
  const botAttributes = useMemo(
    () => [
      { id: 'rate.bot.intelligence', rangeValue: attributes?.intelligence },
      { id: 'rate.bot.speed', rangeValue: attributes?.speed },

      {
        id: 'rate.bot.context',
        value: attributes?.context,
        tooltipLabel: intl.formatMessage(
          { id: 'tooltip.rate.bot.context' },
          {
            context: convertToKFormat(attributes?.context || 0),
            englishPage: llmContextToEnglishPage(attributes?.context),
            botName: bot?.name,
          }
        ),
      },
      {
        id: 'attibute.bot.demos',
        component: (
          <CustomButton
            startIcon={<YouTubeIcon />}
            label={intl.formatMessage({ id: 'attibute.bot.demos' })}
            onClick={handleOpenBotDemo}
            variant="contained"
            size="small"
            sx={{
              height: (theme: Theme) => theme.spacing(4),
            }}
          />
        ),
      },
    ],
    [attributes]
  );

  const creditAttribute = {
    id: 'rate.bot.credit.cost',
    tooltipLabel: intl.formatMessage(
      {
        id: 'tooltip.rate.bot.credit.cost',
      },
      {
        swiftaskTokenCoef: bot?.swiftaskTokenCoef,
        quantityLabel: bot?.quantityLabel,
      }
    ),
    component: (
      <CustomTypography variant="body2">
        {formatNumber(bot?.swiftaskTokenCoef as number)} / {bot?.quantityLabel}
      </CustomTypography>
    ),
  };

  const tags = attributes?.tags?.map((item: any) => ({
    label: item.label,
  }));

  return (
    <CustomBox>
      {attributes && (
        <>
          <STDivider />
          <CustomBox mb={2}>
            <CustomBox
              display="flex"
              gap={1}
              flexWrap="wrap"
              py={(theme: Theme) => theme.spacing(1)}
            >
              {tags?.map((tag: any, index: number) => (
                <STChip
                  key={index}
                  label={tag.label}
                  sx={{ cursor: 'pointer' }}
                />
              ))}
            </CustomBox>
            <CustomBox py={(theme: Theme) => theme.spacing(1)} width="100%">
              {botAttributes.map((attribute, index) =>
                attribute.rangeValue ||
                attribute.value ||
                attributes?.videoDemoUrl ? (
                  <BotAttributeItem
                    key={index}
                    attribute={attribute as BotAttribute}
                  />
                ) : null
              )}
              <BotAttributeItem attribute={creditAttribute} />
            </CustomBox>
            {attributes?.videoDemoUrl && (
              <STDialogVideo
                open={openBotDemo}
                videoUrl={attributes?.videoDemoUrl}
                handleClose={handleCloseBotDemo}
              />
            )}
          </CustomBox>
        </>
      )}
    </CustomBox>
  );
};

export default BotAttributes;
